const getLinkFromContent = contentObj => {
  let { linkTo, lang, staticPage } = contentObj;
  // Return early if static page
  if (staticPage) {
    return `/${lang}${staticPage}`;
  }
  // Given contentType, return path to page as generated in gatsby-node.js
  const contentType = linkTo.sys.contentType.sys.id;
  const downloadTypeSlug = linkTo.fields.downloadType
    ? linkTo.fields.downloadType.toLowerCase().replace(" ", "-")
    : null; // downloadType names need to be modified for URL structure
  const pathByType = {
    post: `/${lang}/resources/blog/${linkTo.fields.slug}/`,
    story: `/${lang}/customers/${linkTo.fields.slug}/`,
    detailPage: `/${lang}/${linkTo.fields.fullSlug}/`,
    productPage: `/${lang}/${linkTo.fields.slug}/`,
    downloadPage: `/${lang}/resources/downloads/${downloadTypeSlug}/${linkTo.fields.slug}/`,
    pressRelease: `/${lang}/company/press/${linkTo.fields.slug}/`,
    event: `/${lang}/events/${linkTo.fields.slug}/`,
    videoLandingPage: `/${lang}/resources/resource-center/videos/${linkTo.fields.slug}`,
  };
  return pathByType[contentType];
};
export default getLinkFromContent;
