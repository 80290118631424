import styled from "styled-components";

const VerboseCTAWrapper = styled("div")<{
  $textColor?: string;
}>`
  display: flex;
  gap: 8px;
  .icon-wrapper,
  .text-wrapper {
    color: ${({ $textColor }) => $textColor || "#000"};
    svg {
      position: relative;
      top: 3px;
    }
  }
  .icon-wrapper {
    span {
      top: 0;
    }
  }
  // Hover
  .icon-wrapper {
    span {
      transition: all 300ms ease-in-out;
      &.icon-arrow-right {
        background-position: -5px 0;
      }
      &.icon-downloadCircular {
        top: 0;
      }
    }
  }
  &:hover {
    .icon-wrapper {
      span {
        &.icon-arrow-right {
          background-position: 0px 0;
        }
        &.icon-downloadCircular {
          top: 5px;
        }
      }
    }
  }
`;
export default VerboseCTAWrapper;
