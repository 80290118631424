import React, { lazy } from "react";
// Carousel
import Carousel from "../../Carousel/Carousel";
import * as Styles from "../HeroBannerStyles";
import Cookies from "js-cookie";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);

interface ModelFProps {
  header?: string;
  subHeader?: string;
  contentCta?: any;
  image?: MainImageType;
  lightboxVideo?: { video_id: string; account_id?: string };
  playerId?: string;
  carousel?: any;
  lang: string;
}

const ModelF = ({
  header,
  image,
  subHeader,
  carousel,
  contentCta,
  lightboxVideo,
  playerId,
  lang,
}: ModelFProps) => {
  return (
    <Styles.HeroBanner $image={image && image.fields.file.url}>
      <ContainerStyle fluid="maxFluid">
        <div id="model-F">
          <RowStyle style={{ height: "100%" }}>
            <ColStyle lg={6} md={12} sm={12}>
              <div className="wrapper-text">
                {/* Animation div */}
                <div
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="linear"
                  data-sal-duration="800"
                >
                  {/* Header */}
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                  {/* Sub Header */}
                  {subHeader && (
                    <div className="sub-header">
                      <Markdown>{subHeader}</Markdown>
                    </div>
                  )}
                  {/* Content CTA */}
                  {contentCta && (
                    <div className="model-F-cta">
                      <ContentCTA contentCta={{ ...contentCta, lang }} />
                    </div>
                  )}
                </div>
              </div>
            </ColStyle>
            <ColStyle lg={6} md={12} sm={12}>
              <div className="video-wrapper">
                {/* Video Player */}
                {lightboxVideo && (
                  <div
                    data-sal="zoom-out"
                    data-sal-delay="500"
                    data-sal-duration="800"
                    data-sal-easing="ease"
                  >
                    <React.Suspense
                      fallback={
                        <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
                          <div
                            className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
                            style={{
                              backgroundSize: "200% 100%",
                              animation: "shimmer 1.5s infinite",
                            }}
                          />
                        </div>
                      }
                    >
                      <LazyReactPlayerLoader
                        playerId={playerId || "RUsPQ8qzl"}
                        baseUrl="https://players.brightcove.net"
                        refNode="video-wrap"
                        // embedType="iframe"
                        accountId={lightboxVideo.account_id || "1160438696001"}
                        videoId={lightboxVideo.video_id}
                        onEmbedCreated={el => {
                          sfmcCookie();
                          el.setAttribute("data-bc-known-user", 1);
                          el.setAttribute(
                            "data-bc-custom-guid",
                            Cookies.get("_sfmc_id_")
                          );
                        }}
                        // onEmbedCreated={el => {
                        //   // if (window && window.location) {
                        //   sfmcCookie(el)
                        //   // el.setAttribute('data-bc-known-user', 1)
                        //   // el.setAttribute('data-bc-custom-guid', Cookies.get('_sfmc_id_'))
                        //   // }
                        // }}
                      />
                    </React.Suspense>
                  </div>
                )}
                {/* Carousel */}
                {carousel && <Carousel {...carousel.fields} />}
              </div>
            </ColStyle>
          </RowStyle>
        </div>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelF;
