import styled from "styled-components";

const CarouselStyles = styled("section")<{
  $backgroundColor?: string;
  $arrowColor?: string;
}>`
  /***************************** Title and Carousel ***************************************/
  /***********************************************************************************/
  /* General background */
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};

  /* Title Carousel */
  .carousel-title {
    display: block;
    text-align: center;
    font-size: 30px;
    color: ${({ $backgroundColor }) => ($backgroundColor ? "#fff" : "#000")};
    padding: 40px 0px 0px 0px;
    letter-spacing: 0px;
    font-weight: bold;
    font-family: "articulat-cf";
  }

  /* Arrow Before */
  .slick-next:before {
    content: "";
    border: solid black;
    border: ${({ $arrowColor }) =>
      $arrowColor ? `solid ${$arrowColor}` : "solid #000"};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  /* Arrow after  */
  .slick-prev:before {
    content: "";
    border: ${({ $arrowColor }) =>
      $arrowColor ? `solid ${$arrowColor}` : "solid #000"};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 20px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  /* Arrow Right and Left */
  .slick-next {
    right: -10px;
  }

  .slick-prev {
    left: -35px;
  }
  /* DOTS Tablet */
  .slick-dots {
    bottom: -10px;
  }

  .slick-dots li.slick-active button:before {
    color: ${({ $arrowColor }) => $arrowColor || "#000"};
  }
  .slick-dots li button:before {
    font-size: 14px;
    line-height: 0px;
    color: ${({ $arrowColor }) => $arrowColor || "#000"};
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .carousel-title {
      padding: 40px 30px 0px 30px;
    }
    .slick-next {
      display: none !important;
    }
    .slick-prev {
      display: none !important;
    }
    .slick-next:before {
      display: none;
    }
    .slick-prev:before {
      display: none;
    }
    .slick-dots {
      position: relative;
      margin-bottom: 40px;
    }
  }
  /* Link Verbose CTA */
  .wrapper-link {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  /* Link Content CTA */
  .content-cta {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  /***************************** Carousel Bubble ************************************************/
  /***********************************************************************************/
  /* Center Bubble */
  .bubble-wrapper {
    .bubble-link {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  #video-Block {
    position: relative;
    height: 500px;
    /* Video Wrapper */
    .video-wrapper-bubble {
      position: relative;
      max-width: 100%;
      width: 100%;
      aspect-ratio: 16 / 9;
      height: 100%;
      overflow: hidden;
      animation: zoom-in-zoom-out 1s ease;
      transition: all 0.2s ease-in-out;
      @keyframes zoom-in-zoom-out {
        0% {
          transform: scale(0.5, 0.5);
        }
        100% {
          transform: scale(1, 1);
        }
      }
      .brightcove-react-player-loader {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        /* Video */
        .video-js[tabindex="-1"] {
          display: contents;
          object-position: center;
        }
        /* .video-js .vjs-tech {
          object-position: top;
        } */
        .vjs-control-bar {
          display: none;
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    #video-Block,
    .default-image-video {
      display: none;
    }
  }

  /* Big Tablet:  max-width: 769px */
  @media only screen and (max-width: 769px) {
    #video-Block {
      height: auto;
      .video-wrapper-bubble {
        aspect-ratio: 16 / 9;
        height: auto;
      }
    }
  }

  /***************************** Hero Logo ************************************************/
  /***********************************************************************************/
  .hero-logo {
    margin-left: 0px;
  }
  #Hero-logo {
    .image-wrapper {
      margin-top: 40px;
      text-align: left;
    }
    /* Hover */
    .image-wrapper:hover {
      margin-left: 10px;
      margin-right: 10px;
      transform: scale(0.98);
      transition: 0.1s;
    }
    /* Link Card Mobile */
    .word-to-click {
      display: none;
    }
    .text-wrapper {
      margin-bottom: 40px;
      a.verbose-cta {
        display: block;
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: 1279px) {
    .hero-logo {
      max-width: 100%;
      margin-left: auto;
      text-align: center;
    }
    #Hero-logo {
      .image-wrapper {
        text-align: center;
      }
      .image-wrapper:hover {
        margin-left: 0px;
        margin-right: 0px;
        transform: none;
        transition: none;
      }
      /* Link Card Mobile */
      .word-to-click {
        display: flex;
        color: #fff;
        justify-content: center;
        font-weight: bold;
        margin-bottom: 20px;
        align-items: baseline;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }
  /***************************** Hero Card ************************************************/
  /***********************************************************************************/
  .hero-card {
    margin-left: 0px;

    .slick-slide {
      padding-right: 10px;
    }
  }
  #HeroCard {
    /* Image */
    .image-wrapper {
      margin-top: 40px;
      margin-bottom: 20px;
      img {
        border-radius: 8px;
        margin-right: 20px;
        box-shadow:
          0 14px 28px rgb(0 0 0 / 25%),
          0 10px 10px rgb(0 0 0 / 22%);
        border-radius: 8px;
      }
    }
    /* Hover */
    .image-wrapper:hover {
      margin-left: 10px;
      margin-right: 10px;
      transform: scale(1.05);
      transition: 0.1s;
    }
    /* Link Card Mobile */
    .word-to-click {
      display: none;
    }
    /* Text */
    .text-wrapper {
      margin-bottom: 40px;
      a.verbose-cta {
        display: block;
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: 1279px) {
    .hero-card {
      max-width: 100%;
      margin-left: auto;
      text-align: center;
    }
  }

  /* Mobile: max-width:   768px */
  @media only screen and (max-width: 768px) {
    #HeroCard {
      .image-wrapper:hover {
        margin-left: 0px;
        margin-right: 0px;
        transform: none;
        transition: none;
      }
      /* Link Card Mobile */
      .word-to-click {
        display: flex;
        color: #fff;
        justify-content: center;
        font-weight: bold;
        margin-bottom: 20px;
        align-items: baseline;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  /***************************** Items ************************************************/
  /***********************************************************************************/
  #Items {
    height: 350px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    margin-bottom: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin: 40px 0px;
    /* Item Content Wrapper */
    .image-wrapper {
      img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        max-height: 150px;
      }
    }
    /* Text */
    .text-wrapper {
      padding: 20px;
      height: 100%;
      display: flex;
      .header {
        color: #000;
        font-weight: bold;
      }
      .body {
        color: #000;
        font-size: 14px;
        margin-top: 5px;
      }
      flex-direction: column;
    }
    /* Verbose */
    .verbose {
      display: flex;
      flex-direction: row;
      margin-top: auto;
      a.verbose-cta {
        display: flex;
        flex-direction: row;
        height: 100%;
      }
      .text-wrapper {
        padding: 5px 0px 0px 0px;
      }
    }
  }
  @media only screen and (max-width: 1279px) {
    #Items {
      margin: 20px;
    }
  }

  /***************************** Resource Card ************************************************/
  /***********************************************************************************/
  #ResourceCard {
    /* PlayTv */
    .playTv {
      position: absolute;
      width: 100px;
      right: 7px;
      top: 10px;
      z-index: 1;
    }
    .resourceImage {
      border-radius: 10px;
    }
    .image-wrapper {
      margin-top: 40px;
      margin-bottom: 20px;
      position: relative;
    }
    .block-wrapper {
      margin-bottom: 40px;
      .header {
        font-family: "articulat-cf";
        font-size: 18px;
        font-weight: 400;
      }
      a.verbose-cta {
        display: block;
        margin-top: 20px;
      }
    }
    @media only screen and (max-width: 1279px) {
      #ResourceCard {
        .block-wrapper {
          margin-bottom: 0px;
        }
      }
    }
  }

  /***************************** Logo ************************************************/
  /***********************************************************************************/
  #logoGrid {
    margin-bottom: 0px;
    img {
      /* height: auto !important; */
      object-fit: contain !important;
      height: 200px !important;
      width: 120px;
      margin-left: auto;
      margin-right: auto;
    }
    @media only screen and (max-width: 1279px) {
    }
  }
`;

/***************************** Customer Card ***************************************/
/***********************************************************************************/
export const CustomerCard = styled("div")<{ $cardBackgroundColor?: string }>`
  .customer-cards {
    border: ${({ $cardBackgroundColor }) =>
      $cardBackgroundColor !== "#fff" ? "none" : "1px solid #adadad"};
    margin: 40px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    background-color: ${({ $cardBackgroundColor }) =>
      $cardBackgroundColor ? $cardBackgroundColor : "#fff"};

    /* Image */
    .wrapper-image {
      width: 41.5%;
      flex: 0 0 41.5%;
      max-width: 41.5%;
    }
    /* Text */
    .card-text {
      width: 58.28%;
      flex: 0 0 58.28%;
      max-width: 58.28%;
      padding: 30px 30px 0px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .body {
        font-family: "articulat-cf";
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0px;
        color: #000000;
      }
      .quote {
        .title {
          display: block;
          margin-top: 10px;
          font-family: "articulat-cf";
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0px;
          color: black;
        }
        .subtitle {
          font-family: "articulat-cf";
          font-size: 18px;
          letter-spacing: 0px;
          color: black;
        }
      }
      a {
        margin-top: 10px;
        display: block;
        font-weight: bold;
      }
      /* Wrapper CTA and logo */
      .cta-logo-wrapper {
        margin-top: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px 0px 0px;
      }
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .customer-cards {
      flex-direction: column;
      max-width: 683px;
      margin-left: auto;
      margin-right: auto;
      margin: auto;
      .wrapper-image {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
      }
      .card-text {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
`;

export default CarouselStyles;
