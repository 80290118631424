import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import ContentfulImage from "@/src/lib/contentful-image";
import VerboseCTA from "../../VerboseCTA/VerboseCTA";
import Markdown from "markdown-to-jsx";

const Items = ({ items, lang }) => {
  const settings = {
    dots: false,
    arrows: true,
    className: "center",
    centerMode: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 10000,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 939,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <ContainerStyle>
      <Slider {...settings}>
        {items &&
          items.map((card, i) => {
            const { header, image, body, verboseCta } = card.fields;
            return (
              <ColStyle
                key={i}
                className="col-style"
                lg={12}
                md={12}
                sm={12}
                gutter={"10"}
              >
                <div id="Items">
                  {image && (
                    <div className="image-wrapper">
                      <ContentfulImage
                        className="main-image"
                        alt={image?.fields?.title || ""}
                        width={image?.fields?.file?.details.image.width || 1000}
                        height={
                          image?.fields?.file?.details.image.height || 1000
                        }
                        quality={80}
                        priority={true}
                        src={image?.fields?.file?.url}
                      />
                    </div>
                  )}
                  <div className="text-wrapper">
                    {header && (
                      <div className="header">
                        <Markdown>{header}</Markdown>
                      </div>
                    )}
                    {body && (
                      <div className="body">
                        <Markdown>{body}</Markdown>
                      </div>
                    )}
                    {verboseCta && (
                      <div className="verbose">
                        <VerboseCTA {...verboseCta.fields} lang={lang} />
                      </div>
                    )}
                  </div>
                </div>
              </ColStyle>
            );
          })}
      </Slider>
    </ContainerStyle>
  );
};

export default Items;
