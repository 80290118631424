// import * as Styles from './FilterStyles'
import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox";
import "pretty-checkbox/src/pretty-checkbox.scss";

const Filter = ({
  cards,
  type,
  papaFilter,
  setPapaFilter,
  translations,
  filterContent,
  setFilterContent,
}) => {
  // All State
  const [list, setList] = useState([]); // All the list categories to render checkbox at the init
  // Before loading page
  useEffect(() => {
    const arrayOfCategories = [];
    // Loop all Categories from your data
    for (let i = 0; i < cards.length; i++) {
      const category = cards[i][type];
      if (category && Array.isArray(category)) {
        for (let j = 0; j < category.length; j++) {
          if (
            category[j].name &&
            !arrayOfCategories.includes(category[j].name) &&
            category[j].name != null
          )
            arrayOfCategories.push(category[j].name);
        }
      } else if (
        category &&
        !arrayOfCategories.includes(category) &&
        category != null
      ) {
        // if categories list doesn't include this category push to the array of catagories.
        arrayOfCategories.push(category);
      }
    }
    setList(arrayOfCategories); // init yout list of categories to render checkbox list

    // // IF URL QUERY Function For PreSelect Filter based on URL
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const { search } = window.location;
      const re = /\[(.*?)\]/g;
      const str = search;
      const matches = str.match(re);
      const listOfFilter = [];
      // for each matches add to FilterContent
      if (matches) {
        for (let i = 0; i < matches.length; i++) {
          // Remove all characters before the '=' and remove '%20'  and push to list Array
          const matche = matches[i]
            .split("=")
            .pop()
            .trim()
            .replace(/%20/g, " ");
          const neWmatche = matche.replace("]", "").replace("[", "");
          listOfFilter.push(neWmatche);
        }
        setFilterContent(listOfFilter); // Pre-select Filter at the before loading.
      }
    }
    //eslint-disable-next-line
  }, []);

  // Checkbox function
  const handleClick = e => {
    // Check
    const categorySelected = e.target.name;
    // Set filterContent
    if (!filterContent.includes(categorySelected)) {
      // if filterContent doesn't include the category selected add it.
      setFilterContent([...filterContent, categorySelected]);
      // Set papaFilter
      const data = papaFilter.map(item => {
        if (item.type === type) {
          const updateFilter = item.value.concat(categorySelected); // add new category Selected
          item.value = updateFilter;
          return item;
        }
        return item;
      });
      setPapaFilter(data);
    } else {
      // uncheck
      setFilterContent(filterContent.filter(item => item !== categorySelected));
      // Set papaFilter
      const data = papaFilter.map(item => {
        if (item.type === type) {
          const updateFilter = item.value.filter(
            item => item !== categorySelected
          ); // remove category Selected
          item.value = updateFilter;
          return item;
        }
        return item;
      });
      setPapaFilter(data);
    }
  };

  // Render all filter from the init list
  const categories = list.sort().map(name => {
    return (
      <div key={name} className="pretty p-switch p-fill">
        <Checkbox
          type="checkbox"
          name={name}
          handleClick={e => handleClick(e)}
          isChecked={filterContent.includes(name)}
        />
        <div className="state p-success">
          <label htmlFor={name}>{translations[name] || name}</label>
        </div>
      </div>
    );
  });

  return (
    // render Checkbox
    <div className="dropdown-content">{categories}</div>
  );
};
export default Filter;
