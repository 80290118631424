import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Link from "next/link";
import ContentfulImage from "@/src/lib/contentful-image";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";

const HeroCard = props => {
  const { items, slideNumber } = props;
  const settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1500,
    slidesToShow: slideNumber || 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  // Display Internal Link
  const displayLink = (image, link, externalLink) => {
    if (link && !externalLink) {
      return (
        <Link prefetch id="HeroCard" href={link}>
          {image && (
            <div className="image-wrapper">
              <ContentfulImage
                className="main-image"
                alt={image?.fields?.title || ""}
                width={image?.fields?.file?.details.image.width || 1000}
                height={image?.fields?.file?.details.image.height || 1000}
                quality={80}
                priority={true}
                src={image?.fields?.file?.url}
              />
            </div>
          )}
          {/* Part for Mobile because link doesn't work on click  */}
          <a href={link} className="word-to-click" rel="noreferrer">
            .
          </a>
        </Link>
      );
    }
    // Display External Link or no Link
    return (
      <a
        href={link || ""}
        target={externalLink ? "_blank" : "_self"}
        id="HeroCard"
        rel="noreferrer"
      >
        {image && (
          <div className="image-wrapper">
            <ContentfulImage
              className="main-image"
              alt={image?.fields?.title || ""}
              width={image?.fields?.file?.details.image.width || 1000}
              height={image?.fields?.file?.details.image.height || 1000}
              quality={80}
              priority={true}
              src={image?.fields?.file?.url}
            />
          </div>
        )}
        {/* Part for Mobile because link doesn't work on click  */}
        {link && (
          <a
            href={link}
            target={externalLink ? "_blank" : "_self"}
            className="word-to-click"
            rel="noreferrer"
          >
            .
          </a>
        )}
      </a>
    );
  };
  return (
    <ContainerStyle className="hero-card">
      <Slider {...settings}>
        {items &&
          items.map((card, i) => {
            const { image } = card.fields;
            return (
              <ColStyle key={i} lg={12} md={12} sm={12} gutter="0">
                {displayLink(
                  image,
                  card.fields.itemLink,
                  card.fields.externalLink
                )}
              </ColStyle>
            );
          })}
      </Slider>
    </ContainerStyle>
  );
};

export default HeroCard;
