"use client";
import React from "react";
import Link from "next/link";
import ga from "@/src/lib/googleAnalyticsService";

import getLinkFromContent from "@/src/lib/getLinkFromContent";
import styled from "styled-components";
import Markdown from "markdown-to-jsx";
import { FaArrowRight, FaRegArrowAltCircleDown } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";

const colorLookup = {
  Black: "#000",
  White: "#fff",
  Cyan: "#fff",
  Navy: "#08088C",
  Yellow: "#FFCE00",
  Orange: "#FF5000",
};
const invertLookup = {
  "#00EAE4": "#08088C",
  "#08088C": "#00EAE4",
  "#000": "#fff",
  "#fff": "#000",
  "#FFCE00": "#FF5000",
  "#FF5000": "#FFCE00",
};

const CTAWrapper = styled("div")<{
  $color: string;
  $isButton: boolean;
  $iconColor: string;
}>`
  color: ${({ $color }) => $color || "#000"};
  display: flex;
  align-items: center;
  padding: ${({ $isButton }) => ($isButton ? "1rem" : "0")};
  gap: 8px;
  .content-cta-icon {
    transition: all 300ms ease-in-out;
    position: relative;
    top: 0px;
  }
  .cta-text {
    /* display: inline-block; */
    font-size: 18px;
    &:after {
      content: "";
      display: ${({ $isButton }) => ($isButton ? "none" : "block")};
      height: 2px;
      width: 0%;
      background: ${({ $iconColor }) => $iconColor};
      transition: width 300ms ease-in-out;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
  &:hover {
    .cta-text {
      &:after {
        width: 100%;
      }
    }
    .icon-downloadCircular {
      transform: scale(1.1);
    }
  }
  // Button styling, add button types here.
  &.button {
    display: inline-block;
    &-outline {
      border: ${({ $color }) => `2px solid ${$color}`};
      &:hover {
        background: ${({ $color }) => $color};
        color: ${({ $color }) => invertLookup[$color]};
      }
    }
    &-solid {
      border: ${({ $color }) => `2px solid ${$color}`};
      background: ${({ $color }) => $color};
      color: ${({ $color }) => invertLookup[$color]};
      &:hover {
        background: ${({ $color }) => invertLookup[$color]};
        border: ${({ $color }) => `2px solid ${invertLookup[$color]}`};
        color: ${({ $color }) => $color};
        &.black {
          border: 2px solid #f1f1f1;
          background: #f1f1f1;
          color: #000;
        }
      }
    }

    &.button-rounded-corner {
      border: ${({ $color }) => `2px solid ${$color}`};
      color: ${({ $color }) => $color};
      border-radius: 50px;
      padding: 15px 50px;
      font-size: 15px;
      &:hover {
        color: ${({ $color }) => invertLookup[$color]};
        background-color: ${({ $color }) => $color};
      }
    }
    @media only screen and (max-width: 1279px) {
      &.button-rounded-corner {
        padding: 8px 30px;
        text-align: center;
      }
    }
  }
`;

export const ContentCTA = props => {
  const {
    ctaText,
    ctaType,
    ctaColor,
    iconColorOverride,
    noExternalLink,
    ctaLongText,
    linkTo,
    externalLink,
    staticPage,
  } = props.contentCta.fields;
  const external = externalLink;
  const linkPath =
    external ||
    getLinkFromContent({
      linkTo: linkTo,
      lang: props.contentCta.lang,
      staticPage: staticPage,
    });
  // Report to GA
  const clickHandler = () => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const label = `${window.location.href} | ${window.location.origin}${linkPath}`;
      ga.trackEvent({
        eventCategory: "CTA",
        eventAction: "Navigation",
        eventLabel: label,
      });
    }
  };
  // Style choices and overrides
  const ctaTypes = ctaType ? ctaType.split("-") : "icon-arrow".split("-");
  const isButton = ctaTypes && ctaTypes[0] === "button";
  const color = ctaColor ? colorLookup[ctaColor] : "#000";
  const iconColor = iconColorOverride ? colorLookup[iconColorOverride] : color;

  // Do not remove the className 'content-cta', it ties to GTM/GA events for this CTA because Chris doesn't trust the GA service
  return (
    <Link
      prefetch
      href={linkPath}
      className={`content-cta`}
      onClick={clickHandler}
      target={noExternalLink ? "_blank" : "_self"}
      suppressHydrationWarning
    >
      <CTAWrapper
        $isButton={isButton}
        $color={color}
        $iconColor={iconColor}
        className={
          isButton
            ? `button ${ctaType} ${ctaColor ? ctaColor.toLowerCase() : ""}`
            : ""
        }
      >
        {!isButton &&
          (ctaTypes[1] === "download" ? (
            <FiDownload color={iconColor} className="content-cta-icon" />
          ) : ctaTypes[1] === "downloadCircular" ? (
            <FaRegArrowAltCircleDown
              color={iconColor}
              className="content-cta-icon"
            />
          ) : (
            <FaArrowRight color={iconColor} className="content-cta-icon" />
          ))}
        {ctaText ? (
          <div className="cta-text">{ctaText}</div>
        ) : ctaLongText ? (
          <div className="cta-text">
            <Markdown>{ctaLongText}</Markdown>
          </div>
        ) : null}
      </CTAWrapper>
    </Link>
  );
};
