import React from "react";
import PropTypes from "prop-types";

import * as Styles from "./SpinnerStyles";

const Spinner = ({
  color = "#000",
  background = "#ddd",
  width = "150px",
  height = "150px",
  border = "20px",
}) => {
  return (
    <Styles.Spinner
      $color={color}
      $background={background}
      $width={width}
      $height={height}
      $border={border}
    />
  );
};

export default Spinner;
