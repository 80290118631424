import React from "react";
import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import ContentfulImage from "@/src/lib/contentful-image";

interface ModelBProps {
  header?: string;
  eyebrowTitle?: string;
  text?: any;
  image?: MainImageType;
}

const ModelB = ({ text, header, image, eyebrowTitle }: ModelBProps) => {
  return (
    <Styles.HeroBanner>
      <ContainerStyle id="model-B">
        <RowStyle>
          <ColStyle lg={12} md={12} sm={12}>
            <div className="wrapper-text">
              {eyebrowTitle && (
                <div className="eyebrowTitle">
                  <Markdown>{eyebrowTitle}</Markdown>
                </div>
              )}
              {header && (
                <div className="header">
                  <Markdown>{header}</Markdown>
                </div>
              )}
              {image && (
                <ContentfulImage
                  alt={image.fields.title || ""}
                  width={image.fields.file?.details.image.width || 1000}
                  height={image.fields.file?.details.image.height || 1000}
                  quality={100}
                  priority={true}
                  src={image.fields.file?.url}
                />
              )}
              {text && (
                <p className={text ? "text" : ""}>
                  {text.content.map((c, i) => {
                    return c.content.map((ind, index) => {
                      if (ind?.marks[0]?.type === "bold") {
                        return <strong key={index}>{ind.value}</strong>;
                      } else {
                        return <span key={index}>{ind.value}</span>;
                      }
                    });
                  })}
                </p>
              )}
            </div>
          </ColStyle>
        </RowStyle>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelB;
