import React, { lazy } from "react";
// Style
import * as Styles from "./CtaBrightcoveStyles";
import Link from "next/link";
import Icon from "./Icon";
// Video
const LazyReactPlayerLoader = lazy(
  () => import("@brightcove/react-player-loader")
);// Modal Video
import useModal from "./Modal/useModal";
import Modal from "./Modal/modal";
import Cookies from "js-cookie";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import Markdown from "markdown-to-jsx";

interface ICtaBrightcove {
  title?: string;
  text: string;
  link?: any;
  style?: string;
  backgroundColor?: string;
  fontSize?: string;
  borderColor?: string;
  textColor: string;
  logoType: any;
  playerId: string;
  lightBoxVideo: any;
  padding?: string;
  lang: string;
}

const CtaBrightcove = ({
  text,
  link,
  style,
  backgroundColor,
  fontSize,
  borderColor,
  logoType,
  textColor,
  playerId,
  lightBoxVideo,
  padding,
  lang,
}: ICtaBrightcove) => {
  const deviceFont = fontSize?.split(",");

  const { isOpen, toggle } = useModal();

  const DisplayCta = () => {
    return (
      <Styles.CtaBrightcove
        $backgroundColor={backgroundColor}
        $desktopSize={fontSize && deviceFont ? deviceFont[0] : undefined}
        $tabletSize={fontSize && deviceFont ? deviceFont[1] : undefined}
        $mobileSize={fontSize && deviceFont ? deviceFont[2] : undefined}
        $borderColor={borderColor}
        $padding={padding}
        $textColor={textColor}
        onClick={lightBoxVideo && toggle}
      >
        {text && (
          <div className={style}>
            {logoType && <Icon name={logoType} />}
            <Markdown>{text}</Markdown>
          </div>
        )}
      </Styles.CtaBrightcove>
    );
  };

  const DisplayVideo = (
    <React.Suspense
      fallback={
        <div className="relative overflow-hidden bg-muted rounded-lg skeleton">
          <div
            className="absolute inset-0 bg-gradient-to-r from-[#ccc] via-muted-foreground/10 to-[#eee] skeleton-animation"
            style={{
              backgroundSize: "200% 100%",
              animation: "shimmer 1.5s infinite",
            }}
          />
        </div>
      }
    >
      <LazyReactPlayerLoader
        playerId={playerId || "RUsPQ8qzl"}
        baseUrl="https://players.brightcove.net"
        refNode="video-wrap"
        accountId={lightBoxVideo?.account_id || "1160438696001"}
        videoId={lightBoxVideo?.video_id}
        onEmbedCreated={el => {
          sfmcCookie();
          el.setAttribute("data-bc-known-user", 1);
          el.setAttribute("data-bc-custom-guid", Cookies.get("_sfmc_id_"));
        }}
      />
    </React.Suspense>
  );
  // Function Check if internal or external Link or Lightbox Video
  const CtaLink = () => {
    if (link) {
      // Cta Link External
      if (link?.includes("https")) {
        return (
          <a href={link} target="_blank">
            <DisplayCta />
          </a>
        );
      } else {
        // Cta Link Internal
        return (
          <Link prefetch href={`/${lang.slice(0, 2)}/${link}`}>
            <DisplayCta />
          </Link>
        );
      }
    } else {
      // Cta Link Lightbox
      return (
        <>
          <DisplayCta />
          <Modal isOpen={isOpen} toggle={toggle}>
            <div className="close-modal" onClick={toggle}>
              <Icon name={"TfiClose"} />
            </div>
            {DisplayVideo}
          </Modal>
        </>
      );
    }
  };

  return CtaLink();
};

export default CtaBrightcove;
