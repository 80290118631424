import React from "react";
import VerboseCTA from "../../VerboseCTA/VerboseCTA";
import CallOutCardSection from "../CallOutCardStyles";
import Markdown from "markdown-to-jsx";
import ContentfulImage from "@/src/lib/contentful-image";
import Link from "next/link";

interface ModelAprops {
  header?: string;
  subHeader?: string;
  image?: any;
  verboseCta?: any;
  backgroundColor?: string;
  clickableCard?: string;
  lang: string;
}

const ModelA = ({
  header,
  subHeader,
  verboseCta,
  image = {},
  backgroundColor,
  clickableCard,
  lang,
}: ModelAprops) => {
  const DisplayCard = () => {
    return (
      <CallOutCardSection $backgroundColor={backgroundColor}>
        <div className="wrapper image-wrapper">
          {image && (
            <ContentfulImage
              alt={image?.fields?.title || ""}
              width={image?.fields?.file?.details.image.width || 1000}
              height={image?.fields?.file?.details.image.height || 1000}
              quality={100}
              priority={true}
              src={image?.fields?.file?.url}
            />
          )}
        </div>
        <div className="wrapper text-wrapper">
          {header && (
            <h6>
              <Markdown>{header}</Markdown>
            </h6>
          )}
          {subHeader && (
            <div>
              <Markdown className="subheader-text">{subHeader}</Markdown>
            </div>
          )}
          {verboseCta && <VerboseCTA {...verboseCta.fields} lang={lang} />}
        </div>
      </CallOutCardSection>
    );
  };
  // Function Check if internal or external Link
  if (!clickableCard) {
    return <DisplayCard />;
  } else if (clickableCard?.includes("https")) {
    return (
      <div
        onClick={() => window.open(clickableCard, "_blank")}
        style={{ cursor: "pointer" }}
      >
        <DisplayCard />
      </div>
    );
  } else {
    return (
      <Link
        prefetch
        href={`/${lang.slice(0, 2)}/${clickableCard}`}
        suppressHydrationWarning
      >
        <DisplayCard />
      </Link>
    );
  }
};
export default ModelA;
