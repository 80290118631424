import React from "react";

import * as Styles from "../HeroBannerStyles";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import ContentfulImage from "@/src/lib/contentful-image";
import { MainImageType } from "@/src/types/types";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";

interface ModelHProps {
  header?: string;
  text?: any;
  contentCta?: any;
  backgroundColor?: string;
  image?: MainImageType;
  secondImage?: MainImageType;
  reverseColumns?: boolean;
  lang: string;
}

const ModelH = ({
  header,
  image,
  backgroundColor,
  contentCta,
  secondImage,
  reverseColumns,
  lang,
}: ModelHProps) => {
  return (
    <Styles.HeroBanner
      $backgroundColor={backgroundColor}
      $reverseColumns={reverseColumns}
    >
      <ContainerStyle fluid="maxFluid">
        <div id="model-H">
          <ContainerStyle style={{ height: "100%" }}>
            <RowStyle className="row-model-H">
              <ColStyle lg={6} md={12} sm={12}>
                <div className="wrapper-text">
                  {image && (
                    <div className="logo">
                      <ContentfulImage
                        alt={image.fields.title || ""}
                        width={image.fields.file?.details.image.width || 1000}
                        height={image.fields.file?.details.image.height || 1000}
                        quality={100}
                        priority={true}
                        src={image.fields.file?.url}
                      />
                    </div>
                  )}
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                  <div className="wrapper-button">
                    {contentCta && (
                      <ContentCTA contentCta={{ ...contentCta, lang }} />
                    )}
                  </div>
                </div>
              </ColStyle>
              <ColStyle lg={6} md={12} sm={12}>
                <div className="image-wrapper-second-image">
                  {secondImage && (
                    <ContentfulImage
                      alt={secondImage.fields.title || ""}
                      width={
                        secondImage.fields.file?.details.image.width || 1000
                      }
                      height={
                        secondImage.fields.file?.details.image.height || 1000
                      }
                      quality={100}
                      priority={true}
                      src={secondImage.fields.file?.url}
                    />
                  )}
                </div>
              </ColStyle>
            </RowStyle>
          </ContainerStyle>
        </div>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelH;
