import { MainImageType } from "@/src/types/types";
import styled from "styled-components";

export const HeroBanner = styled("div")<{
  $image?: string;
  $overlayImage?: string;
  $backgroundColor?: string;
  $reverseColumns?: boolean;
  $containerGrid?: string;
  $secondImage?: MainImageType;
  $noImageMobileAnTablet?: boolean;
}>`
  display: flex;
  width: 100%;
  /************************************** Model A **********************************************/
  /*********************************************************************************************/
  #model-A {
    height: 525px;
    /* Row */
    .row-model-A {
      height: 100%;
      justify-content: center;
      /* Text */
      .wrapper-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          text-align: left;
          font-size: 35px;
          font-family: var(--primary-font-family);
          font-weight: 800;
          color: #000000;
          text-transform: uppercase;
          padding: 5px 0px;
          letter-spacing: 0px;
          line-height: 1;
        }
        .sub-title {
          text-align: left;
          font-family: "articulat-cf";
          font-size: 25px;
          letter-spacing: 0px;
          color: #000000;
          padding: 5px 0px;
        }
        .text {
          text-align: left;
          font-family: "articulat-cf";
          font-size: 20px;
          letter-spacing: 0px;
          color: #000000;
          padding: 5px 0px 30px 0px;
        }
        /* Button For Video */
        .wrapper-button {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          a {
            margin-right: 10px;
            margin-bottom: 10px;
          }
          #hero-cta {
            width: 170px;
            height: 60px;
            border: 2px solid #000000;
            opacity: 1;
            a {
              height: 100%;
              justify-content: center;
              align-content: center;
              align-items: center;
            }
            .icon-play {
              top: 0px;
            }
            p {
              font-size: 1.2rem;
              font-family: "articulat-cf" !important;
              font-weight: 400;
              margin-bottom: 0px;
              text-align: left;
            }
            p:after {
              height: 0px;
            }
          }
        }
      }
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #model-A {
      height: 100%;
      .row-model-A {
        justify-content: flex-start;
        .wrapper-text .wrapper-button {
          justify-content: flex-start;
          margin-bottom: 30px;
        }
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    #model-A {
      .row-model-A {
        flex-direction: column-reverse;
      }
    }
  }

  /************************************** Model B **********************************************/
  /*********************************************************************************************/
  #model-B {
    text-align: center;
    /* Text */
    .wrapper-text {
      text-align: center;
      /* Eyebrow */
      .eyebrowTitle {
        padding: 40px 0px 20px 0px;
        font-size: 20px;
        font-family: "Agrandir";
        color: #000000;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        /* if H1 in Markdown */
        h1 {
          font-size: 20px;
          font-family: "Agrandir";
          color: #000000;
          text-transform: uppercase;
          font-weight: bold;
          text-align: center;
          margin: 0;
        }
      }
      /* Header  */
      .header {
        padding: 0px 30% 30px 30%;
        font-family: "articulat-cf";
        font-size: 25px;
        letter-spacing: 0px;
        font-weight: 400;
        color: #000000;
        /* if H2 in Markdown */
        h2 {
          font-family: "articulat-cf";
          font-size: 25px;
          letter-spacing: 0px;
          font-weight: 400;
          color: #000000;
        }
      }

      img {
        margin: 0 auto;
      }
      .text {
        font-family: "articulat-cf";
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000000;
        padding: 160px 20% 30px 20%;
        height: auto;
        background-color: #f4f6f8;
        align-items: center;
        margin-top: -135px;
        justify-content: center;
        line-height: 1.2;
      }
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #model-B {
      .wrapper-text {
        .header {
          padding: 10px 0px 30px;
        }
        .text {
          margin-top: -90px;
          padding: 100px 10% 30px 10%;
        }
      }
    }
  }

  /************************************** Model C **********************************************/
  /*********************************************************************************************/
  #model-C {
    height: 393px;
    width: 100%;
    margin: 0 auto;
    background: ${({ $image, $overlayImage }) =>
      $image && $overlayImage
        ? `${$overlayImage}, url(${$image})  no-repeat center center`
        : ` url(${$image}) no-repeat center center`};
    background-size: cover;

    > div > div > div {
      padding: 0 !important;
    }
    /* Row */
    .row-model-C {
      height: 100%;
      justify-content: center;
      color: #fff;
      flex-direction: column;
      display: flex;
      width: 100%;
      /* Text */
      .wrapper-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Eyebrow */
        .eyebrowTitle,
        h1 {
          font-size: 20px;
          font-family: "Agrandir";
          color: #fff;
          text-transform: uppercase;
          padding: 0px;
          margin-bottom: 0px;
          font-weight: bold;
          line-height: 1.1;
        }
        /* Text */
        .text {
          text-align: left;
          font-size: 45px;
          line-height: 1.1;
          font-family: "Agrandir";
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          padding: 5px 0px;
          h2 {
            text-align: left;
            font-size: 45px;
            font-family: "Agrandir";
            font-weight: 800;
            color: #fff;
            text-transform: uppercase;
          }
        }
        /* Header */
        .header {
          line-height: 1.1;
          padding: 0px 0px 10px 0px;
          text-align: left;
          font-family: "articulat-cf";
          font-size: 25px;
          letter-spacing: 0px;
          font-weight: 500;
          color: #fff;
          /* if H2 in Markdown */
          h2 {
            text-align: left;
            font-family: "articulat-cf";
            font-size: 25px;
            letter-spacing: 0px;
            font-weight: 500;
            color: #fff;
            line-height: 1.1;
          }
        }

        a,
        .content-cta,
        .cta-text {
          font-family: "articulat-cf";
          font-size: 20px;
        }
        .wrapper-button {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          #hero-cta {
            height: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            p {
              margin-bottom: 10px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #model-C .row-model-C .wrapper-text {
      width: 80%;
      margin: 0;
    }

    #model-C > div > div > div {
      padding: 0 30px !important;
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    #model-C {
      height: auto;
      padding: 30px 0px;
      .row-model-C .wrapper-text {
        width: 100%;
        /* Eyebrow */
        .eyebrowTitle,
        h1 {
          font-size: 18px;
        }
        /* Text */
        .text {
          font-size: 32px;
          h2 {
            font-size: 32px;
          }
        }
      }
    }
  }
  /************************************** Model D **********************************************/
  /*********************************************************************************************/
  #model-D {
    height: 230px;
    width: 100%;
    background: ${({ $image, $overlayImage }) =>
      $image && $overlayImage
        ? `${$overlayImage}, url(${$image})  no-repeat center center`
        : ` url(${$image}) no-repeat center center`};
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    /* Row */
    .row-model-D {
      height: 100%;
      justify-content: center;
      color: #fff;
      flex-direction: column;
      display: flex;
      /* Text */
      .wrapper-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; /* Eyebrow */
        .header,
        h1 {
          font-size: 65px;
          font-family: "Agrandir";
          color: #04dcd7;
          text-transform: uppercase;
          padding: 0px;
          font-weight: bold;
          margin-bottom: 0px;
          text-align: center;
        }
        .sub-header {
          font-size: 30px;
          font-family: "Agrandir";
          color: #fff;
          text-transform: uppercase;
          padding: 0px;
          font-weight: bold;
          margin-bottom: 0px;
          text-align: center;
        }
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    #model-D .row-model-D .wrapper-text {
      h1 {
        font-size: 40px;
      }
      .sub-header {
        font-size: 25px;
      }
    }
  }
  /************************************** Model E **********************************************/
  /*********************************************************************************************/
  #model-E {
    height: 925px;
    width: 100%;
    background: ${({ $image }) => `url(${$image})`} no-repeat center center;
    background-size: cover;
    display: flex;
    /* Image */
    #Hero-logo .image-wrapper {
      text-align: center;
    }
    /* Text */
    .wrapper-text {
      height: 100%;
      margin-left: 5%;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .header,
      h1,
      h2 {
        font-size: 65px;
        font-family: "Agrandir";
        color: #fff;
        text-transform: uppercase;
        padding: 0px;
        font-weight: bold;
        margin-bottom: 0px;
        text-align: left;
      }
      .sub-header {
        font-size: 25px;
        font-family: "Agrandir";
        color: #fff;
        line-height: 1.5;
        padding: 0px;
        margin-bottom: 0px;
        margin-top: 20px;
        text-align: left;
        max-width: 80%;
      }
      .model-E-cta {
        margin-top: 20px;
      }
      .model-E-cta-logo {
        margin-top: 40px;
      }
    }
  }
  /* Tablet: max-width:   1440px */
  @media only screen and (max-width: 1440px) {
    #model-E {
      display: inherit;
    }
  }
  /* Mobile: max-width:   767px */
  @media only screen and (max-width: 767px) {
    #model-E {
      height: auto;
      padding: 20px 0px;
      .wrapper-text {
        max-width: 100%;
        .header,
        h1,
        h2 {
          font-size: 40px;
        }
        .sub-header {
          font-size: 25px;
          max-width: 100%;
        }
        .model-E-cta {
          margin-top: 40px;
          text-align: center;
        }
        .model-E-cta-logo {
          text-align: center;
          margin-top: 0px;
        }
      }
    }
  }
  /************************************** Model F **********************************************/
  /*********************************************************************************************/
  #model-F {
    height: 925px;
    width: 100%;
    background: ${({ $image }) => `url(${$image})`} no-repeat center center;
    background-size: cover;
    /* Left Side Text */
    .wrapper-text {
      height: 100%;
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .header,
      h1,
      h2 {
        font-size: 65px;
        font-family: "Agrandir";
        color: #fff;
        text-transform: uppercase;
        padding: 0px;
        font-weight: bold;
        margin-bottom: 0px;
        text-align: left;
      }
      .sub-header {
        font-size: 25px;
        font-family: "Agrandir";
        color: #fff;
        line-height: 1.5;
        padding: 0px;
        margin-bottom: 0px;
        margin-top: 20px;
        text-align: left;
        max-width: 80%;
      }
      .model-F-cta {
        margin-top: 20px;
      }
    }
    /* Video */
    .video-wrapper {
      position: relative;
      max-width: 100%;
      width: 100%;
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      .brightcove-react-player-loader {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        .bc-iframe,
        .bc-iframe body,
        .bc-player-RUsPQ8qzl_default,
        .bc-player-default_default,
        .bc-player-RUsPQ8qzl_default .vjs-poster {
          background-color: transparent !important;
        }
        .video-js .vjs-tech {
          position: unset;
        }
        .video-js {
          width: 100%;
          height: 100%;
          /* position: absolute; */
        }
      }
    }
  }
  /* Tablet: max-width:   1440px */
  @media only screen and (max-width: 1440px) {
    #model-F {
      height: auto;
    }
  }
  /* Mobile: max-width:   767px */
  @media only screen and (max-width: 767px) {
    #model-F {
      height: auto;
      padding: 20px 0px;
      .wrapper-text {
        .header,
        h1,
        h2 {
          font-size: 40px;
        }
        .sub-header {
          font-size: 25px;
          max-width: 100%;
        }
        .model-F-cta {
          margin-top: 40px;
          text-align: center;
        }
      }
      .video-wrapper {
        padding: 20px;
      }
    }
  }
  /************************************** Model G **********************************************/
  /*********************************************************************************************/
  #model-G {
    width: 100%;
    background: ${({ $backgroundColor }) =>
      $backgroundColor ? $backgroundColor : "#000"};
    /* Row */
    .row-model-G {
      height: 100%;
      justify-content: center;
      color: #fff;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: ${({ $containerGrid }) =>
        $containerGrid ? $containerGrid : "100%"};
      margin-left: auto;
      margin-right: auto;
      flex-direction: ${({ $reverseColumns }) =>
        $reverseColumns ? "row-reverse" : "row"};
      background-image: ${({ $secondImage }) =>
        $secondImage ? `url(${$secondImage?.fields.file.url})` : ""};
      background-size: cover;
      /* Text */
      .wrapper-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 0px;
        .line-bar {
          border-left-width: 2px;
          border-style: solid;
          border-image: linear-gradient(
              to top,
              #4ea9d1,
              #aa6c7e,
              #f76531,
              #efd900
            )
            0 0 0 100%;
          padding-left: 30px;
        }
        /* Eyebrow */
        .eyebrowTitle,
        .eyebrowTitle h1 {
          font-size: 20px;
          font-family: "Agrandir";
          color: #fff;
          padding: 0px;
          font-weight: bold;
          margin-bottom: 0px;
        }
        /* Header */
        .header {
          text-align: left;
          font-family: "articulat-cf";
          font-size: 25px;
          letter-spacing: 0px;
          font-weight: 500;
          color: #fff;

          h2 {
            line-height: 1.1;
          }
        }
        /* Button */
        .wrapper-button {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .Circle,
          .Square {
            margin-right: 10px;
          }
        }
      }
    }

    /*CTA Form Button */
    #model-G-form {
      display: flex;
      align-items: center;
      margin-right: 20px;
      span {
        display: none;
      }
      p {
        padding: 5px 20px;
        margin: 0px;
        font-size: 16px;
        font-family: "articulat-cf" !important;
        font-weight: 100;
        border: 1px solid #fff;
        border-radius: 50px;
        &::after {
          display: none;
        }
        &:hover {
          color: #000000;
          background-color: #fff;
        }
      }
      a {
        margin: 0px;
      }
    }

    /* Tablet  */
    @media only screen and (max-width: 1279px) {
      .row-model-G {
        .wrapper-image {
          display: ${({ $noImageMobileAnTablet }) =>
            $noImageMobileAnTablet ? "none" : ""};
        }
      }
    }

    /* Tablet and Mobile: max-width: 899px */
    @media only screen and (max-width: 899px) {
      .row-model-G {
        .wrapper-text {
          /* Button */
          .wrapper-button {
            flex-direction: column;
            a {
              margin-top: 10px;
            }
          }
        }
      }
    }
    /* Tablet and Mobile: max-width: 768px */
    @media only screen and (max-width: 768px) {
      .row-model-G {
        flex-direction: column-reverse;
        .wrapper-image {
          text-align: center;
        }
      }
    }
  }

  /************************************** Model H **********************************************/
  /*********************************************************************************************/
  #model-H {
    height: auto;
    width: 100%;
    padding: 20px 0px;
    background: ${({ $backgroundColor }) =>
      $backgroundColor ? $backgroundColor : "#000"};
    /* Row */
    .row-model-H {
      height: 100%;
      justify-content: center;
      color: #fff;
      flex-direction: ${({ $reverseColumns }) =>
        $reverseColumns ? "row-reverse" : "row"};
      display: flex;
      @media screen and (max-width: 1279px) {
        flex-direction: ${({ $reverseColumns }) =>
          $reverseColumns ? "column-reverse" : "column"};
      }
      /* Text */
      .wrapper-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Image Logo */
        .logo {
          width: 300px;
          margin-bottom: 10px;
        }
        /* Eyebrow */
        .eyebrowTitle,
        h1 {
          font-size: 20px;
          font-family: "Agrandir";
          color: #fff;
          text-transform: uppercase;
          padding: 0px;
          font-weight: bold;
          margin-bottom: 0px;
        }
        /* Text */
        .text {
          text-align: left;
          font-size: 45px;
          font-family: "Agrandir";
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          padding: 5px 0px;
          h2 {
            text-align: left;
            font-size: 45px;
            font-family: "Agrandir";
            font-weight: 800;
            color: #fff;
            text-transform: uppercase;
          }
        }
        /* Header */
        .header {
          padding: 0px 0px 10px 0px;
          text-align: left;
          font-family: "articulat-cf";
          font-size: 25px;
          letter-spacing: 0px;
          font-weight: 500;
          color: #fff;
          /* if H2 in Markdown */
          h2 {
            text-align: left;
            font-family: "articulat-cf";
            font-size: 25px;
            letter-spacing: 0px;
            font-weight: 500;
            color: #fff;
            line-height: 1.1;
          }
        }

        a,
        .content-cta,
        .cta-text {
          font-family: "articulat-cf";
          font-size: 20px;
        }
        .wrapper-button {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          #hero-cta {
            height: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            p {
              margin-bottom: 10px;
              font-weight: 400;
            }
          }
        }
      }
      .image-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #model-H {
      height: auto;
      .row-model-H {
        .wrapper-text {
          width: 80%;
          padding: 30px 0px;
        }
        .image-wrapper {
          width: 240px;
          padding: 30px 0px;
        }
      }
      .image-wrapper-second-image img {
        max-height: 500px;
      }
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    #model-H {
      padding: 40px 0px;
      .row-model-H .wrapper-text {
        width: 100%;
      }
    }
  }
  /************************************** Model I **********************************************/
  /*********************************************************************************************/
  #model-I {
    height: 665px;
    width: 100%;
    background: ${({ $image, $overlayImage }) =>
      $image && $overlayImage
        ? `${$overlayImage}, url(${$image})  no-repeat center center`
        : ` url(${$image}) no-repeat center center`};
    background-size: cover;
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4); */
    /* Row */
    .row-model-I {
      height: 100%;
      justify-content: center;
      color: #fff;
      flex-direction: column;
      display: flex;
      /* Text */
      .wrapper-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Eyebrow */
        .eyebrowTitle,
        .eyebrowTitle h1,
        .eyebrowTitle p {
          font-size: 20px;
          font-family: "Agrandir";
          color: #fff;
          text-transform: uppercase;
          padding: 0px;
          font-weight: bold;
          margin-bottom: 0px;
        }

        /* Text */
        .text {
          text-align: left;
          font-size: 45px;
          font-family: "Agrandir";
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          padding: 5px 0px;
          line-height: 0.9;
          h2 {
            text-align: left;
            font-size: 45px;
            font-family: "Agrandir";
            font-weight: 800;
            color: #fff;
            text-transform: uppercase;
          }
        }
        /* Header */
        .header {
          padding: 0px 0px 10px 0px;
          text-align: left;
          font-family: "articulat-cf";
          font-size: 25px;
          letter-spacing: 0px;
          font-weight: 500;
          color: #fff;
          /* if H2 in Markdown */
          h2 {
            text-align: left;
            font-family: "articulat-cf";
            font-size: 25px;
            letter-spacing: 0px;
            font-weight: 500;
            color: #fff;
          }
        }

        a,
        .content-cta,
        .cta-text {
          font-family: "articulat-cf";
          font-size: 20px;
        }
        .wrapper-button {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          #hero-cta {
            height: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            p {
              margin-bottom: 10px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #model-I .row-model-I .wrapper-text {
      width: 80%;
    }
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    #model-I {
      height: auto;
      padding: 30px 0px;
      .row-model-I .wrapper-text {
        width: 100%;
        .text {
          font-size: 20px;
          padding: 10px 0px;
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
        .header {
          font-size: 18px;
        }
        /* Eyebrow */
        .eyebrowTitle,
        .eyebrowTitle h1,
        .eyebrowTitle p {
          font-size: 18px;
        }
      }
    }
  }

  /************************************** Model J **********************************************/
  /*********************************************************************************************/
  #model-J {
    height: auto;
    width: 100%;
    background: ${({ $backgroundColor }) =>
      $backgroundColor ? $backgroundColor : "#000"};
    /* Row */
    .row-model-J {
      height: 100%;
      justify-content: center;
      color: #fff;
      flex-direction: ${({ $reverseColumns }) =>
        $reverseColumns ? "row-reverse" : "row"};
      display: flex;
      @media screen and (max-width: 1279px) {
        flex-direction: ${({ $reverseColumns }) =>
          $reverseColumns ? "column-reverse" : "column"};
        height: auto;
      }
      /* Parent Wrapper */
      .parent-wrapper-text {
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      /* Text */
      .wrapper-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 40px;
        max-width: 100%;
        border-left-width: 2px;
        border-style: solid;
        border-image: linear-gradient(
            to bottom,
            #4ea9d1,
            #aa6c7e,
            #f76531,
            #efd900
          )
          0 0 0 100%;
        /* Eyebrow */
        .eyebrowTitle,
        .eyebrowTitle h1,
        .eyebrowTitle h2 {
          font-size: 25px;
          font-family: "articulat-cf";
          color: #fff;
          text-transform: uppercase;
          padding: 0px;
          margin-bottom: 0px;
        }
        /* Header */
        .header,
        .header h1,
        .header h2 {
          margin-top: auto;
          padding: 0px 0px 10px 0px;
          text-align: left;
          font-family: "articulat-cf";
          font-size: 35px;
          letter-spacing: 0px;
          font-weight: 500;
          color: #fff;
        }
        /* subHeader */
        .subHeader,
        .subHeader h1,
        .subHeader h2,
        .subHeader p {
          text-align: left;
          line-height: 1;
          letter-spacing: 0px;
          font-size: 65px;
          font-family: "Agrandir";
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          padding: 5px 0px;
        }
        /* Link CTA */
        a,
        .content-cta,
        .cta-text {
          font-family: "articulat-cf";
          font-size: 20px;
        }
        .wrapper-button {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: auto;
          .content-cta {
            margin-right: 20px;
          }
          #hero-cta {
            height: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            a {
              font-size: 20px;
              border: 2px solid #fff;
              color: #fff;
              border-radius: 50px;
              padding: 8px 30px;
              svg {
                font-size: 20px;
                margin-top: 3px;
              }
            }
            p {
              font-weight: 400;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    /* Image */
    .image-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  /* Big Tablet:  max-width: 1500px */
  @media only screen and (max-width: 1500px) {
    #model-J {
      .row-model-J .parent-wrapper-text {
        .wrapper-text {
          /* Header */
          .header,
          .header h1,
          .header h2 {
            font-size: 20px;
          }
          /* subHeader */
          .subHeader,
          .subHeader h1,
          .subHeader h2,
          .subHeader p {
            font-size: 45px;
          }
        }
      }
    }
  }
  /* Big Tablet:  max-width: 1340px */
  @media only screen and (max-width: 1340px) {
    #model-J {
      .row-model-J .parent-wrapper-text {
        padding: 20px 0px;
        .wrapper-text {
          /* Button */
          .wrapper-button {
            flex-direction: column;
            flex-flow: column;
            margin-top: 20px;
            .button-rounded-corner {
              padding: 8px 30px;
            }
            #hero-cta {
              margin-top: 10px;
              a {
                padding: 2px 30px;
              }
            }
          }
        }
      }
    }
  }
  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    #model-J {
      padding: 0px 0px;
      .row-model-J .parent-wrapper-text {
        .wrapper-text {
          width: 100%;
          padding: 20px 20px;
          /* Eyebrow */
          .eyebrowTitle,
          .eyebrowTitle h1,
          .eyebrowTitle h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }
          /* Header */
          .header,
          .header h1,
          .header h2 {
            font-size: 20px;
          }
          /* subHeader */
          .subHeader,
          .subHeader h1,
          .subHeader h2,
          .subHeader p {
            font-size: 85px;
          }
        }
      }
      /* Image */
      .image-wrapper {
        display: none;
      }
    }
  }
  /* Tablet and Mobile: max-width: 800px */
  @media only screen and (max-width: 860px) {
    #model-J {
      .row-model-J .parent-wrapper-text {
        .wrapper-text {
          /* subHeader */
          .subHeader,
          .subHeader h1,
          .subHeader h2,
          .subHeader p {
            font-size: 30px;
          }
        }
      }
    }
  }
`;
