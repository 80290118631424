import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-grid-system";
import Link from "next/link";
import ContentfulImage from "@/src/lib/contentful-image";

const LogoGrid = ({ items, lang }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <Container id="logoGrid">
      <Slider {...settings}>
        {items &&
          items.map(element => {
            const { slug, story, logo, name, logoUrl } = element.fields;
            let elementLink = "";
            if (slug) {
              elementLink = `/${lang}/partners/${slug}/`;
            } else {
              elementLink =
                story && story[0] && story[0].slug
                  ? `/${lang}/customers/${story[0].slug}/`
                  : "";
            }
            return (
              <div key={`element-grid-${Math.random()}`}>
                {elementLink !== "" && (
                  <Link prefetch href={elementLink}>
                    {logo ? (
                      <ContentfulImage
                        className="main-image"
                        alt={logo?.fields?.title || ""}
                        width={logo?.fields?.file?.details.image.width || 1000}
                        height={
                          logo?.fields?.file?.details.image.height || 1000
                        }
                        quality={80}
                        priority={true}
                        src={logo?.fields?.file?.url}
                        key={`img-grid-${Math.random()}`}
                      />
                    ) : (
                      // eslint-disable-next-line
                      <img
                        key={`img-grid-${Math.random()}`}
                        src={logoUrl}
                        alt={name}
                        title={`Read ${name} customer story`}
                      />
                    )}
                  </Link>
                )}
                {elementLink === "" &&
                  (logo ? (
                    <ContentfulImage
                      className="main-image"
                      alt={logo?.fields?.title || ""}
                      width={logo?.fields?.file?.details.image.width || 1000}
                      height={logo?.fields?.file?.details.image.height || 1000}
                      quality={80}
                      priority={true}
                      src={logo?.fields?.file?.url}
                      key={`img-grid-${Math.random()}`}
                    />
                  ) : (
                    // eslint-disable-next-line
                    <img
                      key={`img-grid-${Math.random()}`}
                      src={logoUrl}
                      alt={name}
                    />
                  ))}
              </div>
            );
          })}
      </Slider>
    </Container>
  );
};

export default LogoGrid;
