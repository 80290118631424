import React from "react";
import * as Styles from "./FilterStyles";
import Filter from "./Filter";

const FilterBar = ({
  types,
  Allcards,
  papaFilter,
  setPapaFilter,
  translations,
  filterContent,
  setFilterContent,
}) => {
  // Function to open Dropdown
  const openDropdown = type => {
    const classExist = document.getElementById(type).classList.contains("show"); // show dropdown content
    const ArrowExist = document
      .getElementById(`arrow-${type}`)
      .classList.contains("arrow-up"); // show arrow up and down

    // if your div ID type contain show
    if (classExist) {
      // remove show class
      document.getElementById(type).classList.remove("show");
    } else {
      // All div whose contain 'show' remove the class.
      const allShowClass = document.getElementsByClassName("show");
      while (allShowClass.length) allShowClass[0].classList.remove("show");
      // Add class show on div id=type
      document.getElementById(type).classList.add("show");
    }
    // Arrow up and down
    if (ArrowExist) {
      document.getElementById(`arrow-${type}`).classList.remove("arrow-up");
    } else {
      // check all class arrow-up and remove it.
      const allShowClass = document.getElementsByClassName("arrow-up");
      while (allShowClass.length) allShowClass[0].classList.remove("arrow-up");
      // add arrow up
      document.getElementById(`arrow-${type}`).classList.add("arrow-up");
    }
  };

  return (
    <>
      {types &&
        types.map((type, index) => {
          return (
            <Styles.DropdownStyle key={index}>
              <div id={type} className="dropdown">
                <div
                  className="button-gradient"
                  onClick={() => openDropdown(type)}
                >
                  {type == "offices"
                    ? translations["Locations"]
                    : translations[type] || type}
                  <i id={`arrow-${type}`} className="arrow-down" />
                </div>
                <Filter
                  cards={Allcards}
                  type={type}
                  setPapaFilter={setPapaFilter}
                  papaFilter={papaFilter}
                  translations={translations}
                  filterContent={filterContent}
                  setFilterContent={setFilterContent}
                />
              </div>
            </Styles.DropdownStyle>
          );
        })}
    </>
  );
};
export default FilterBar;
