import React from "react";

import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";

interface ModelDProps {
  header?: string;
  subHeader?: string;
  image?: MainImageType;
  overlayImage?: string;
}

const ModelD = ({ header, image, subHeader, overlayImage }: ModelDProps) => {
  return (
    <Styles.HeroBanner
      $overlayImage={overlayImage}
      $image={image && image.fields.file?.url}
    >
      <ContainerStyle fluid={"true"}>
        <div id="model-D">
          <ContainerStyle style={{ height: "100%" }}>
            <RowStyle className="row-model-D">
              <ColStyle lg={12} md={12} sm={12}>
                <div className="wrapper-text">
                  {subHeader && (
                    <div className="sub-header">
                      <Markdown>{subHeader}</Markdown>
                    </div>
                  )}
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                </div>
              </ColStyle>
            </RowStyle>
          </ContainerStyle>
        </div>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelD;
