import React from "react";

import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import CtaBrightcove from "../../CTABrightcove";
import CallToAction from "../../CallToAction/CallToAction";
import ContentfulImage from "@/src/lib/contentful-image";

interface ModelGProps {
  header?: string;
  eyebrowTitle?: string;
  backgroundColor?: string;
  image?: MainImageType;
  contentCta?: any;
  multipleCta?: any;
  reverseColumns?: boolean;
  containerGrid?: string;
  noImageMobileAnTablet?: boolean;
  formLightBox?: boolean;
  secondImage?: MainImageType;
  translations?: any;
  lang: string;
}

const ModelG = ({
  header,
  image,
  backgroundColor,
  eyebrowTitle,
  contentCta,
  multipleCta,
  reverseColumns,
  containerGrid,
  noImageMobileAnTablet,
  secondImage,
  formLightBox,
  translations,
  lang,
}: ModelGProps) => {
  // Form Config
  const getFormContent = lang => {
    const formContent = {
      partner: {
        node_locale: lang,
        smallText: translations["Talk to Us"],
        title: translations["Become a Partner"],
        body: null,
        eloquaFormName: "partner_inquiry_new",
        submitText: translations["submit"],
        thanksTitle: translations["THANKS FOR GETTING IN TOUCH"],
        thanksMessage:
          translations[
            "Check your inbox for confirmation of your request and the next steps"
          ],
        comments: "",
        extraFields: {},
        translations: translations,
        formElementId: "partner-form",
      },
    };
    return formContent["partner"];
  };
  return (
    <Styles.HeroBanner
      $backgroundColor={backgroundColor}
      $reverseColumns={reverseColumns}
      $containerGrid={containerGrid}
      $secondImage={secondImage}
      $noImageMobileAnTablet={noImageMobileAnTablet}
    >
      <ContainerStyle fluid="maxFluid">
        <div id="model-G">
          <RowStyle className="row-model-G">
            <ColStyle
              lg={!image ? 12 : 6}
              md={!image || noImageMobileAnTablet ? 12 : 6}
              sm={12}
            >
              <div className="wrapper-text">
                <div className="line-bar">
                  {eyebrowTitle && (
                    <div className="eyebrowTitle">
                      <Markdown>{eyebrowTitle}</Markdown>
                    </div>
                  )}
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                  <div
                    style={{ display: `${!contentCta && "none"}` }}
                    className="wrapper-button"
                  >
                    {contentCta && (
                      <CtaBrightcove lang={lang} {...contentCta.fields} />
                    )}
                  </div>
                  <div
                    style={{ display: `${!multipleCta && "none"}` }}
                    className="wrapper-button"
                  >
                    {multipleCta?.map((cta, i) => (
                      <CtaBrightcove lang={lang} key={i} {...cta.fields} />
                    ))}
                  </div>
                  <div
                    style={{ display: `${!formLightBox && "none"}` }}
                    className="wrapper-button"
                  >
                    {formLightBox && (
                      <CallToAction
                        id="model-G-form"
                        callToActionCopy={"Get started"}
                        iconColor={"#fff"}
                        linkPath={"#"}
                        iconImage={""}
                        lang={lang}
                        textColor={"#fff"}
                        className={""}
                        lightboxData={{
                          type: "form",
                          content: getFormContent(lang),
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </ColStyle>
            {image && (
              <ColStyle
                className="wrapper-image"
                lg={6}
                md={6}
                sm={12}
                gutter="0"
              >
                {image && (
                  <ContentfulImage
                    alt={image.fields.title || ""}
                    width={image.fields.file?.details.image.width || 1000}
                    height={image.fields.file?.details.image.height || 1000}
                    quality={100}
                    priority={true}
                    src={image.fields.file?.url}
                  />
                )}
              </ColStyle>
            )}
          </RowStyle>
        </div>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelG;
