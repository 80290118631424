import React from "react";
import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import CallToAction from "../../CallToAction/CallToAction";
import { ContentCTA } from "../../ContentCTA/ContentCTA";

interface ModelCProps {
  header?: string;
  eyebrowTitle?: string;
  contentCta?: any;
  text?: any;
  image?: MainImageType;
  lightboxVideo?: { video_id: string };
  lighboxVideoCtaText?: string;
  playerId?: string;
  formLightBox?: boolean;
  overlayImage?: string;
  translations?: any;
  lang: string;
}

const ModelC = ({
  header,
  text,
  image,
  eyebrowTitle,
  lightboxVideo,
  lighboxVideoCtaText,
  contentCta,
  formLightBox,
  playerId,
  overlayImage,
  lang,
  translations,
}: ModelCProps) => {
  // Form Config
  const getFormContent = lang => {
    const formContent = {
      phone: {
        node_locale: lang,
        slug: "",
        smallText: translations["Talk to Us"],
        title: translations["See how Brightcove can work for you"],
        body: null,
        eloquaFormName: "contactSales",
        offerId: "7018c000001qVahAAE",
        submitText: translations["Get Started"],
        thanksTitle: translations["THANKS FOR GETTING IN TOUCH"],
        thanksMessage:
          translations[
            "Check your inbox for confirmation of your request and the next steps"
          ],
        comments: "",
        extraFields: {},
        eventObj: {
          eventCategory: "Side CTA",
          eventAction: "Form submit",
          eventLabel: `Sales - ${lang.slice(0, 2)} | 'Sales'`,
        },
        translations: translations,
      },
    };
    return formContent["phone"];
  };
  return (
    <Styles.HeroBanner
      $overlayImage={overlayImage}
      $image={image && image.fields.file?.url}
    >
      <ContainerStyle fluid={"true"}>
        <div id="model-C">
          <ContainerStyle style={{ height: "100%" }}>
            <RowStyle className="row-model-C">
              <ColStyle lg={6} md={12} sm={12}>
                <div className="wrapper-text">
                  {eyebrowTitle && (
                    <div className="eyebrowTitle">
                      <Markdown>{eyebrowTitle}</Markdown>
                    </div>
                  )}
                  {
                    text &&
                      text.content.map((c, i) =>
                        c.nodeType === "heading-2" ? (
                          <h2 className="text" key={i}>
                            {c.content[0].value}
                          </h2>
                        ) : (
                          <p className="text" key={i}>
                            {c.content[0].value}
                          </p>
                        )
                      )
                    // <Markdown className="text">{text}</Markdown>
                  }
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                  <div className="wrapper-button">
                    {lightboxVideo && (
                      <CallToAction
                        id="hero-cta"
                        callToActionCopy={lighboxVideoCtaText || "Watch this"}
                        linkPath="#"
                        iconImage="play"
                        iconColor="#fff"
                        lightboxData={{
                          type: "video",
                          content: lightboxVideo.video_id,
                          playerId,
                        }}
                        textColor="#fff"
                        playerId={playerId}
                        lang={lang}
                      />
                    )}
                    {contentCta && (
                      <ContentCTA contentCta={{ ...contentCta, lang }} />
                    )}
                    {formLightBox && (
                      <CallToAction
                        id="hero-cta"
                        callToActionCopy={
                          translations["Contact Sales for more information"]
                        }
                        iconColor={"#fff"}
                        linkPath={"#"}
                        iconImage={"arrow"}
                        lang={lang}
                        textColor={"#fff"}
                        className={"class"}
                        lightboxData={{
                          type: "form",
                          content: getFormContent(lang),
                        }}
                      />
                    )}
                  </div>
                </div>
              </ColStyle>
            </RowStyle>
          </ContainerStyle>
        </div>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelC;
