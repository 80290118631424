import React from "react";
import Link from "next/link";
import getLinkFromContent from "@/src/lib/getLinkFromContent";
import ga from "@/src/lib/googleAnalyticsService";
import VerboseCTAWrapper from "./VerboseCTAStyles";
import Markdown from "markdown-to-jsx";
import { FaArrowRight, FaRegArrowAltCircleDown } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";

function VerboseCTA({
  body,
  headline,
  icon,
  iconColor,
  linkTo,
  staticPage,
  externalLink,
  noExternalLink,
  lang,
}) {
  const linkPath =
    externalLink || getLinkFromContent({ linkTo, staticPage, lang });
  // const linkPath = getLinkFromContent({ linkTo, staticPage, node_locale })
  const clickHandler = () => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const label = `${window.location.href} | ${window.location.origin}${linkPath}`;
      ga.trackEvent({
        eventCategory: "CTA",
        eventAction: "Navigation",
        eventLabel: label,
      });
    }
  };
  // If External Link
  return externalLink ? (
    <a
      href={linkPath}
      onClick={clickHandler}
      className="verbose-cta"
      target={noExternalLink ? "_self" : "_blank"}
      rel="noreferrer"
    >
      <VerboseCTAWrapper $textColor={iconColor}>
        <div className="icon-wrapper">
          {icon === "download" ? (
            <FiDownload
              color={iconColor || "#000"}
              className="content-cta-icon"
            />
          ) : icon === "downloadCircular" ? (
            <FaRegArrowAltCircleDown
              color={iconColor || "#000"}
              className="content-cta-icon"
            />
          ) : (
            <FaArrowRight
              color={iconColor || "#000"}
              className="content-cta-icon"
            />
          )}
        </div>
        <div className="text-wrapper">
          {headline && <Markdown>{headline}</Markdown>}
          {body && <Markdown>{body}</Markdown>}
        </div>
      </VerboseCTAWrapper>
    </a>
  ) : (
    // IF Not External Link
    <Link
      prefetch
      href={linkPath}
      onClick={clickHandler}
      className="verbose-cta"
      target={noExternalLink ? "_self" : "_blank"}
    >
      <VerboseCTAWrapper $textColor={iconColor}>
        <div className="icon-wrapper">
          {icon === "download" ? (
            <FiDownload
              color={iconColor || "#000"}
              className="content-cta-icon"
            />
          ) : icon === "downloadCircular" ? (
            <FaRegArrowAltCircleDown
              color={iconColor || "#000"}
              className="content-cta-icon"
            />
          ) : (
            <FaArrowRight
              color={iconColor || "#000"}
              className="content-cta-icon"
            />
          )}
        </div>
        <div className="text-wrapper">
          {headline && <Markdown>{headline}</Markdown>}
          {body && (
            <Markdown
              options={{
                wrapper: "span",
                forceWrapper: true,
              }}
            >
              {body}
            </Markdown>
          )}
        </div>
      </VerboseCTAWrapper>
    </Link>
  );
}

export default VerboseCTA;
