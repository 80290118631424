import styled from "styled-components";

// 3 Style for CtaBrightcove.
export const CtaBrightcove = styled("div")<{
  $backgroundColor?: string;
  $desktopSize?: string;
  $tabletSize?: string;
  $mobileSize?: string;
  $borderColor?: string;
  $padding?: string;
  $textColor?: string;
}>`
  font-size: ${({ $desktopSize }) => ($desktopSize ? $desktopSize : "20px")};
  cursor: pointer;
  svg {
    margin-right: 5px;
    transition: transform 0.2s; /* Animation */
  }
  /* Circle Style */
  .Circle {
    display: flex;
    width: fit-content;
    color: ${({ $textColor }) => ($textColor ? $textColor : "#000")};
    border: 1px solid
      ${({ $borderColor, $backgroundColor }) =>
        $borderColor ? $borderColor : $backgroundColor};
    border-radius: 50px;
    padding: ${({ $padding }) => ($padding ? $padding : "15px 50px")};
    align-items: center;
    background: ${({ $backgroundColor }) =>
      $backgroundColor ? $backgroundColor : "#fff"};
    transition: transform 0.2s;
    /* Hover */
    &:hover {
      color: ${({ $backgroundColor }) =>
        $backgroundColor !== "transparent" ? $backgroundColor : "#000"};
      background: ${({ $textColor }) => ($textColor ? $textColor : "#000")};
    }
  }
  /* Square Style */
  .Square {
    display: flex;
    width: fit-content;
    color: ${({ $textColor }) => ($textColor ? $textColor : "#000")};
    border: 1px solid
      ${({ $borderColor, $backgroundColor }) =>
        $borderColor ? $borderColor : $backgroundColor};
    padding: ${({ $padding }) => ($padding ? $padding : "15px 50px")};
    align-items: center;
    background: ${({ $backgroundColor }) =>
      $backgroundColor ? $backgroundColor : "#fff"};
    /* Hover */
    &:hover {
      color: ${({ $backgroundColor }) =>
        $backgroundColor !== "transparent" ? $backgroundColor : "#000"};
      background: ${({ $textColor }) => ($textColor ? $textColor : "#000")};
    }
  }
  /* No border  */
  .NoBorder {
    display: flex;
    width: fit-content;
    color: ${({ $textColor }) => ($textColor ? $textColor : "#000")};
    align-items: center;
    text-decoration: underline;
    /* Hover */
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    font-size: ${({ $tabletSize }) => ($tabletSize ? $tabletSize : "20px")};
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    font-size: ${({ $mobileSize }) => ($mobileSize ? $mobileSize : "16px")};
    .Circle,
    .Square {
      padding: 5px 20px;
    }
  }
`;
