import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CallOutCards from "../../CallOutCard/CallOutCard";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";

const CallOutCard = ({ items }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <ContainerStyle>
      <Slider {...settings}>
        {items &&
          items.map((card, index) => {
            return <CallOutCards key={index} {...card.fields} />;
          })}
      </Slider>
    </ContainerStyle>
  );
};

export default CallOutCard;
