import React from "react";
import CustomerCards from "./Model/CustomerCards";
import CallOutCardCarousel from "./Model/CallOutCardCarousel";
import LogoGrid from "./Model/LogoGrid";
import Items from "./Model/Items";

import CarouselStyles from "./CarouselStyles";
import ResourceCard from "./Model/ResourceCard";
import HeroCard from "./Model/HeroCard";
import HeroLogo from "./Model/HeroLogo";
import ContainerStyle from "../../Global_Components/GridSystem/ContainerStyle";
import RowStyle from "../../Global_Components/GridSystem/RowStyle";
import ColStyle from "../../Global_Components/GridSystem/ColStyle";
import VerboseCTA from "../VerboseCTA/VerboseCTA";
import { ContentCTA } from "../ContentCTA/ContentCTA";
import Markdown from "markdown-to-jsx";

const Carousel = props => {
  const {
    carouselBlockBubble,
    carouselBlock,
    carouselBlockCallOutCard,
    carouselBlockLogo,
    carouselBlockITems,
    carouselBlockResource,
    carouselBlockHeroCard,
    carouselBlockHeroLogo,
    node_locale,
    header,
    image,
    backgroundColor,
    maxFluid,
    arrowColor,
    verboseCta,
    slidesToShow,
    contentCta,
    lang,
  } = props;
  const langFromLocale = lang;
  return (
    <ContainerStyle fluid={maxFluid ? "maxFluid" : "true"}>
      <CarouselStyles
        $backgroundColor={backgroundColor}
        $arrowColor={arrowColor}
      >
        {header && (
          <h3>
            <Markdown className="carousel-title">{header}</Markdown>
          </h3>
        )}
        <RowStyle>
          {/* Carousel Block Hero Logo */}
          {carouselBlockHeroLogo && (
            <HeroLogo
              items={carouselBlockHeroLogo}
              slideNumber={slidesToShow}
            />
          )}
          {/* Carousel Block Hero Card */}
          {carouselBlockHeroCard && (
            <HeroCard
              items={carouselBlockHeroCard}
              slideNumber={slidesToShow}
            />
          )}
          {/* Carousel Block Items */}
          {carouselBlockITems && (
            <Items items={carouselBlockITems} lang={lang} />
          )}
          {/* Carousel Block Resource Card */}
          {carouselBlockResource && (
            <ResourceCard lang={lang} items={carouselBlockResource} />
          )}
          {/* Carousel Block Customer Card */}
          {carouselBlock && <CustomerCards lang={lang} items={carouselBlock} />}
          {carouselBlockCallOutCard && (
            <CallOutCardCarousel items={carouselBlockCallOutCard} />
          )}
        </RowStyle>
        {carouselBlockLogo && (
          <LogoGrid items={carouselBlockLogo} lang={langFromLocale} />
        )}

        {verboseCta && (
          <RowStyle>
            <ColStyle lg={12} md={12} sm={12}>
              <div className="wrapper-link">
                <VerboseCTA {...verboseCta.fields} lang={lang} />
              </div>
            </ColStyle>
          </RowStyle>
        )}
        {contentCta ? (
          <RowStyle>
            <ColStyle lg={12} md={12} sm={12}>
              <div className="content-cta">
                <ContentCTA contentCta={{ ...contentCta, lang }} />
              </div>
            </ColStyle>
          </RowStyle>
        ) : null}
      </CarouselStyles>
    </ContainerStyle>
  );
};

export default Carousel;
