import React from "react";
import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";
import Carousel from "../../Carousel/Carousel";

interface ModelEProps {
  header?: string;
  subHeader?: string;
  image?: MainImageType;
  carousel?: any;
  contentCta?: any;
  lang: string;
}

const ModelE = ({
  header,
  image,
  subHeader,
  carousel,
  contentCta,
  lang,
}: ModelEProps) => {
  return (
    <Styles.HeroBanner $image={image && image.fields.file?.url}>
      <ContainerStyle fluid="maxFluid">
        <div id="model-E">
          <RowStyle style={{ height: "100%" }}>
            <ColStyle lg={12} md={12} sm={12}>
              <div className="wrapper-text">
                {/* Animation div */}
                <div
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="linear"
                  data-sal-duration="800"
                >
                  {/* Header */}
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                  {/* Sub Header */}
                  {subHeader && (
                    <div className="sub-header">
                      <Markdown>{subHeader}</Markdown>
                    </div>
                  )}
                  {/* Content CTA for Hero-Card */}
                  {contentCta && carousel.fields.carouselBlockHeroCard && (
                    <div className="model-E-cta">
                      <ContentCTA contentCta={{ ...contentCta, lang }} />
                    </div>
                  )}
                </div>
                {/* Carousel */}
                {carousel && (
                  <div
                    data-sal="zoom-out"
                    data-sal-delay="500"
                    data-sal-duration="800"
                    data-sal-easing="ease"
                  >
                    <Carousel
                      lang={lang}
                      {...carousel.fields}
                      maxFluid="maxFluid"
                    />
                  </div>
                )}
                {/* Content CTA for Hero-Logo */}
                {contentCta && carousel.fields.carouselBlockHeroLogo && (
                  <div
                    data-sal="zoom-out"
                    data-sal-delay="500"
                    data-sal-duration="800"
                    data-sal-easing="ease"
                    className="model-E-cta-logo"
                  >
                    <ContentCTA contentCta={{ ...contentCta, lang }} />
                  </div>
                )}
              </div>
            </ColStyle>
          </RowStyle>
        </div>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelE;
