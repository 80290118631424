import React from "react";
import Slider from "react-slick";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import VerboseCTA from "../../VerboseCTA/VerboseCTA";
import ContentfulImage from "@/src/lib/contentful-image";
import Markdown from "markdown-to-jsx";
import { ImageCard } from "../../ResourceContainer/ResourceCard/ResourceImage";

const ResourceCard = ({ items, lang }) => {
  const settings = {
    dots: true,
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <ContainerStyle>
      <Slider {...settings}>
        {items &&
          items.map((card, i) => {
            const { header, image, type, verboseCta, playTv } = card.fields;
            return (
              <ColStyle
                key={i}
                className="col-style"
                lg={12}
                md={12}
                sm={12}
                gutter={"10"}
              >
                <div id="ResourceCard">
                  <div className="image-wrapper">
                    {playTv && (
                      <Image
                        width={100}
                        height={100}
                        className="playTv"
                        src="https://images.ctfassets.net/ov6oufbwq1qf/5pQ8BqmkOm5QSM7pJDrA2a/501eb182793c314a5832df3796392fee/PLAY_TV_by_Brightcove_Logo_White.png"
                        alt="PlayTv"
                      />
                    )}
                    {image ? (
                      <ContentfulImage
                        className="main-image"
                        alt={image?.fields?.title || ""}
                        width={image?.fields?.file?.details.image.width || 1000}
                        height={
                          image?.fields?.file?.details.image.height || 1000
                        }
                        quality={80}
                        priority={true}
                        src={image?.fields?.file?.url}
                      />
                    ) : (
                      ImageCard(type)
                    )}
                  </div>
                  <div className="block-wrapper">
                    {header && (
                      <h6 className="header">
                        <Markdown>{header}</Markdown>
                      </h6>
                    )}
                    {verboseCta && (
                      <VerboseCTA lang={lang} {...verboseCta.fields} />
                    )}
                  </div>
                </div>
              </ColStyle>
            );
          })}
      </Slider>
    </ContainerStyle>
  );
};

export default ResourceCard;
