import React from "react";

import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import CallToAction from "../../CallToAction/CallToAction";
import { ContentCTA } from "../../ContentCTA/ContentCTA";

interface ModelIProps {
  header?: string;
  eyebrowTitle?: string;
  text?: any;
  contentCta?: any;
  backgroundColor?: string;
  image?: MainImageType;
  lightboxVideo?: { video_id: string };
  lighboxVideoCtaText?: string;
  playerId?: string;
  overlayImage?: string;
  lang: string;
}

const ModelI = ({
  header,
  text,
  image,
  eyebrowTitle,
  lightboxVideo,
  lighboxVideoCtaText,
  contentCta,
  playerId,
  overlayImage,
  lang,
}: ModelIProps) => {
  return (
    <Styles.HeroBanner
      $overlayImage={overlayImage}
      $image={image && image.fields.file.url}
    >
      <ContainerStyle fluid={"maxFluid"}>
        <div id="model-I">
          <ContainerStyle style={{ height: "100%" }}>
            <RowStyle className="row-model-I">
              <ColStyle lg={6} md={12} sm={12}>
                <div className="wrapper-text">
                  {eyebrowTitle && (
                    <div className="eyebrowTitle">
                      <Markdown>{eyebrowTitle}</Markdown>
                    </div>
                  )}
                  {text &&
                    text.content.map((c, i) =>
                      c.nodeType === "heading-2" ? (
                        <h2 className="text" key={i}>
                          {c.content[0].value}
                        </h2>
                      ) : (
                        <p className="text" key={i}>
                          {c.content[0].value}
                        </p>
                      )
                    )}
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                  <div className="wrapper-button">
                    {lightboxVideo && (
                      <CallToAction
                        id="hero-cta"
                        callToActionCopy={lighboxVideoCtaText || "Watch this"}
                        linkPath="#"
                        iconImage="play"
                        iconColor="#fff"
                        lightboxData={{
                          type: "video",
                          content: lightboxVideo.video_id,
                          playerId,
                        }}
                        textColor="#fff"
                        playerId={playerId}
                        lang={lang}
                      />
                    )}
                    {contentCta && (
                      <ContentCTA contentCta={{ ...contentCta, lang }} />
                    )}
                  </div>
                </div>
              </ColStyle>
            </RowStyle>
          </ContainerStyle>
        </div>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelI;
