import React from "react";
import ModelA from "./Model/ModelA";
import ModelB from "./Model/ModelB";
import ModelC from "./Model/ModelC";
import ModelD from "./Model/ModelD";
import ModelE from "./Model/ModelE";
import ModelF from "./Model/ModelF";
import ModelG from "./Model/ModelG";
import ModelH from "./Model/ModelH";
import ModelI from "./Model/ModelI";
import ModelJ from "./Model/ModelJ";
import { MainImageType } from "@/src/types/types";

interface IHeroBanner {
  title: string;
  header?: string;
  type: string;
  modelATitles?: string;
  subHeader?: string;
  eyebrowTitle: string;
  text?: any;
  backgroundColor?: string;
  image?: MainImageType;
  secondImage?: MainImageType;
  overlayImage?: string;
  contentCta?: any;
  playerId?: string;
  lightboxVideo?: { video_id: string; account_id?: string };
  lighboxVideoCtaText?: string;
  formLightBox?: boolean;
  carousel?: any;
  ReverseColumns?: boolean;
  containerGrid?: string;
  noImageMobileAnTablet?: boolean;
  lang: string;
  translations: any;
}

const HeroBanner = (props: IHeroBanner) => {
  const { type } = props;
  return (
    <>
      {type === "model-A" && <ModelA {...props} />}
      {type === "model-B" && <ModelB {...props} />}
      {type === "model-C" && <ModelC {...props} />}
      {type === "model-D" && <ModelD {...props} />}
      {type === "model-E" && <ModelE {...props} />}
      {type === "model-F" && <ModelF {...props} />}
      {type === "model-G" && <ModelG {...props} />}
      {type === "model-H" && <ModelH {...props} />}
      {type === "model-I" && <ModelI {...props} />}
      {type === "model-J" && <ModelJ {...props} />}
    </>
  );
};

export default HeroBanner;
