import styled from "styled-components";
import { BREAK_POINTS } from "../../../lib/breakPoints";

const CallOutCardSection = styled("div")<{
  $backgroundColor?: string;
}>`
  /***************************** ModelA **********************************************/
  /***********************************************************************************/
  max-width: 1080px;
  margin: 30px auto;
  display: flex;
  border: 1px solid #adadad;
  background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  > .image-wrapper {
    flex: 0 0 40%;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  > .text-wrapper {
    flex: 0 0 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    h6 {
      font-family: "articulat-cf";
      font-size: 25px;
      margin-bottom: 15px;
      font-weight: 700;
      line-height: 1.1;
    }
    .subheader-text {
      font-size: 18px;
      line-height: 1.3;
      max-width: 88%;
      font-weight: 300;
    }
    .verbose-cta {
      margin-top: 15px;
      font-size: 18px;
      .icon-wrapper {
        span {
          top: 2px;
        }
      }
    }
  }
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    max-width: 100%;
    margin: 30px;
  }
  @media only screen and (${BREAK_POINTS.MOBILE}) {
    display: block;
    > .text-wrapper {
      padding: 50px 30px;
    }
  }
  /***************************** ModelB **********************************************/
  /***********************************************************************************/
  .model-B {
    max-width: 1080px;
    display: flex;
    border: 1px solid #adadad;
    background: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
    > .image-wrapper {
      flex: 0 0 50%;
    }
    > .text-wrapper {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px;
      h6 {
        font-family: "articulat-cf";
        font-size: 25px;
      }
      > p {
        font-size: 18px;
        line-height: 1.3;
        max-width: 88%;
      }
      .subHeader,
      .subHeader p {
        font-family: "Agrandir";
        font-size: 35px;
        font-weight: bold;
      }
      .verbose-cta {
        margin-top: 15px;
        font-size: 18px;
        cursor: pointer;
        .icon-wrapper {
          span {
            top: 2px;
          }
        }
      }
    }
  }

  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    .model-B {
      max-width: 100%;
      > .text-wrapper {
        padding: 50px 30px;
        .subHeader,
        .subHeader p {
          font-size: 28px;
        }
      }
    }
  }
  @media only screen and (${BREAK_POINTS.MOBILE}) {
    .model-B {
      display: block;
      > .text-wrapper {
        padding: 50px 30px;
        .subHeader,
        .subHeader p {
          font-size: 25px;
        }
      }
    }
  }
`;
export default CallOutCardSection;
