import styled from "styled-components";

const Modal = styled.div`
  .modal-overlay {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }

  .modal-box {
    display: block;
    background: #000;
    min-width: 960px;
    min-height: 540px;
    animation: show-animation 500ms ease;
    z-index: 2;
    .close-modal {
      width: 960px;
      display: flex;
      justify-content: flex-end;
      svg {
        color: #fff;
        background: transparent;
        position: absolute;
        margin-right: 10px;
        margin-top: 10px;
        font-size: 25px;
        z-index: 1;
        width: auto;
        cursor: pointer;
      }
    }
  }
  @keyframes show-animation {
    from {
      opacity: 0;
      transform: scale(1.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  /* Video */
  .brightcove-react-player-loader {
    position: relative;
  }
  .video-js {
    width: 100% !important;
    height: 0px !important;
    padding-top: 56.25%;
    position: absolute;
  }

  /* Big Tablet:  max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    .modal-box {
      width: 100vw;
      min-width: 100vw;
      min-height: 200px;
      .close-modal {
        width: 100vw;
      }
    }

    .video-js {
      width: 100% !important;
      height: 0px !important;
      padding-top: 56.25%;
    }
  }
  /* Mobile:  max-width: 800px */
  @media only screen and (max-width: 800px) {
  }
`;
export default Modal;
