import React from "react";
import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";
import CallToAction from "../../CallToAction/CallToAction";
import ContentfulImage from "@/src/lib/contentful-image";

interface ModelJProps {
  header?: string;
  eyebrowTitle?: string;
  subHeader?: string;
  text?: any;
  contentCta?: any;
  backgroundColor?: string;
  image?: MainImageType;
  slightboxVideo?: { video_id: string };
  lightboxVideo?: any;
  lighboxVideoCtaText?: string;
  reverseColumns?: boolean;
  playerId?: string;
  lang: string;
}

const ModelJ = ({
  header,
  image,
  backgroundColor,
  subHeader,
  lightboxVideo,
  lighboxVideoCtaText,
  eyebrowTitle,
  playerId,
  contentCta,
  reverseColumns,
  lang,
}: ModelJProps) => {
  return (
    <Styles.HeroBanner
      $backgroundColor={backgroundColor}
      $reverseColumns={reverseColumns}
    >
      <div id="model-J">
        <ContainerStyle fluid="maxFluid" style={{ height: "100%" }}>
          <RowStyle className="row-model-J">
            <ColStyle lg={6} md={12} sm={12}>
              <div className="parent-wrapper-text">
                <div className="wrapper-text">
                  {eyebrowTitle && (
                    <div className="eyebrowTitle">
                      <Markdown>{eyebrowTitle}</Markdown>
                    </div>
                  )}
                  {header && (
                    <div className="header">
                      <Markdown>{header}</Markdown>
                    </div>
                  )}
                  {subHeader && (
                    <div className="subHeader">
                      <Markdown>{subHeader}</Markdown>
                    </div>
                  )}
                  <div
                    style={{
                      display: `${!contentCta && !lightboxVideo && "none"}`,
                    }}
                    className="wrapper-button"
                  >
                    {contentCta && (
                      <ContentCTA contentCta={{ ...contentCta, lang }} />
                    )}
                    {lightboxVideo && (
                      <CallToAction
                        id="hero-cta"
                        callToActionCopy={lighboxVideoCtaText || "Watch this"}
                        linkPath="#"
                        iconImage="play"
                        iconColor="#fff"
                        lightboxData={{
                          type: "video",
                          content: lightboxVideo.video_id,
                          playerId,
                        }}
                        textColor="#fff"
                        playerId={playerId}
                        lang={lang}
                      />
                    )}
                  </div>
                </div>
              </div>
            </ColStyle>
            <ColStyle lg={6} md={12} sm={12} gutter="0">
              <div className="image-wrapper">
                {image && (
                  <ContentfulImage
                    alt={image.fields.title || ""}
                    width={image.fields.file?.details.image.width || 1000}
                    height={image.fields.file?.details.image.height || 1000}
                    quality={100}
                    priority={true}
                    src={image.fields.file?.url}
                  />
                )}
              </div>
            </ColStyle>
          </RowStyle>
        </ContainerStyle>
      </div>
    </Styles.HeroBanner>
  );
};

export default ModelJ;
