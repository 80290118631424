import React, { useEffect, useState } from "react";

import * as Styles from "../HeroBannerStyles";
import { MainImageType } from "@/src/types/types";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import Markdown from "markdown-to-jsx";
import { ContentCTA } from "../../ContentCTA/ContentCTA";
import CallToAction from "../../CallToAction/CallToAction";
import ContentfulImage from "@/src/lib/contentful-image";

interface ModelAProps {
  header?: string;
  subHeader?: string;
  modelATitles?: any;
  contentCta?: any;
  text?: any;
  image?: MainImageType;
  lightboxVideo?: { video_id: string };
  lighboxVideoCtaText?: string;
  playerId?: string;
  lang: string;
}

const ModelA = ({
  contentCta,
  text,
  subHeader,
  image,
  modelATitles,
  lightboxVideo,
  lighboxVideoCtaText,
  playerId,
  lang,
}: ModelAProps) => {
  // Function to rotate text every 3 seconds.
  const [newName, setnewName] = useState(modelATitles[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setnewName(
        modelATitles[
          modelATitles.indexOf(newName) === modelATitles.length - 1
            ? 0
            : modelATitles.indexOf(newName) + 1
        ]
      );
    }, 3000);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [newName]);

  return (
    <Styles.HeroBanner>
      <ContainerStyle id="model-A">
        <RowStyle className="row-model-A">
          <ColStyle lg={4} md={12} sm={12}>
            <div className="wrapper-text">
              <div className="title">{newName}</div>
              {subHeader && (
                <div className="sub-title">
                  <Markdown>{subHeader}</Markdown>
                </div>
              )}
              {text && (
                <Markdown className="text">
                  {text.content[0].content[0].value}
                </Markdown>
              )}
              <div className="wrapper-button">
                <ContentCTA contentCta={{ ...contentCta, lang }} />
                {lightboxVideo && (
                  <CallToAction
                    id="hero-cta"
                    callToActionCopy={lighboxVideoCtaText || "Watch this"}
                    linkPath="#"
                    iconImage="play"
                    lightboxData={{
                      type: "video",
                      content: lightboxVideo.video_id,
                      playerId,
                    }}
                    className="button-outline"
                    playerId={playerId}
                    lang={lang}
                  />
                )}
              </div>
            </div>
          </ColStyle>
          <ColStyle lg={8} md={12} sm={12}>
            {image && (
              <ContentfulImage
                alt={image.fields.title || ""}
                width={image.fields.file?.details.image.width || 1000}
                height={image.fields.file?.details.image.height || 1000}
                quality={100}
                priority={true}
                src={image.fields.file?.url}
              />
            )}
          </ColStyle>
        </RowStyle>
      </ContainerStyle>
    </Styles.HeroBanner>
  );
};

export default ModelA;
