import styled from "styled-components";
import PropTypes from "prop-types";

export const Spinner = styled("div")<{
  $color: string;
  $background: string;
  $width: string;
  $height: string;
  $border: string;
}>`
  border: ${({ $border }) => $border} solid ${({ $background }) => $background};
  border-top: ${({ $border }) => $border} solid ${({ $color }) => $color};
  border-radius: 50%;
  width: ${({ $width }) => $width};;
  height: ${({ $height }) => $height};;
  animation: spin 2s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}`;
