import React from "react";
import ModelA from "./Models/ModelA";
import ModelB from "./Models/ModelB";

interface ICallOutCard {
  header: string;
  subHeader: string;
  image: any;
  verboseCta: any;
  backgroundColor?: string;
  clickableCard?: string;
  type: string;
  lang: string;
}

const CallOutCard = (props: ICallOutCard) => {
  const { type } = props;
  return (
    <section>
      {type === "model-A" && <ModelA {...props} />}
      {type === "model-B" && <ModelB {...props} />}
    </section>
  );
};

export default CallOutCard;
