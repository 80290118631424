/* eslint-disable @next/next/no-img-element */
import React from "react";

const IMAGE = [
  {
    title: "Video",
    src: "https://images.ctfassets.net/ov6oufbwq1qf/3mOX4wkrWlYfUyEoHddLr5/2ee7c23ed4260737d607337a11838b2b/ResourceCenterBanners_Video_5.png",
    alt: "Video",
  },
  {
    title: "Guide",
    src: "https://images.ctfassets.net/ov6oufbwq1qf/5kWlJsPtNusJVFvqk7dLh6/59edc5df5a70414d2117abfaf8ab0bc6/ResourceCenterBanners_Guide_1.png",
    alt: "Guide",
  },
  {
    title: "White Paper",
    src: "https://images.ctfassets.net/ov6oufbwq1qf/3Ep1iGneVMwr2Vaqd3u9p2/0255dd57f4107c9d22d9c73888a78c14/ResourceCenterBanners_Whitepaper_2.png",
    alt: "White Paper",
  },
  {
    title: "Playbook",
    src: "https://images.ctfassets.net/ov6oufbwq1qf/3JnBA8uVDOZTVs7tc2fnXG/722ba0c04ac0f39aeddab31796fe7e04/ResourceCenterBanners_Playbook_3.png",
    alt: "Playbook",
  },
  {
    title: "Training",
    src: "https://images.ctfassets.net/ov6oufbwq1qf/4VIIrb7tcYygaap9pLoGtI/04ca5b0660e1823e02f44c883ae955f6/ResourceCenterBanners_Training_3.png",
    alt: "Training",
  },
  {
    title: "Report",
    src: "https://images.ctfassets.net/ov6oufbwq1qf/4806ZmmTGwLkkPrhfLiR3a/b421826d8040b39db1bd29d256ce9836/ResourceCenterBanners_Whitepaper_3.png",
    alt: "Report",
  },
];

export const ImageCard = category => {
  for (let i = 0; i < IMAGE.length; i++) {
    if (IMAGE[i].title === category) {
      return (
        <img className="resourceImage" src={IMAGE[i].src} alt={IMAGE[i].alt} />
      );
    }
  }
};
