import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Styles from "../CarouselStyles";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import ContentfulImage from "@/src/lib/contentful-image";
import { ContentCTA } from "../../ContentCTA/ContentCTA";
import Markdown from "markdown-to-jsx";

const CustomerCards = ({ items, lang }) => {
  const slider = React.useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <ContainerStyle>
      <Slider ref={slider} {...settings}>
        {items &&
          items.map((card, i) => {
            const {
              image,
              body,
              quoteName,
              quoteTitle,
              contentCta,
              bottomLogo,
              contentful_id,
              backgroundColor,
            } = card.fields;
            const isQuote = quoteName && quoteTitle;
            return (
              <ColStyle key={i} className="col-style" lg={12} md={12} sm={12}>
                <Styles.CustomerCard
                  $cardBackgroundColor={backgroundColor || "#fff"}
                >
                  <div className="customer-cards">
                    {/* Image */}
                    <div className="wrapper-image">
                      <ContentfulImage
                        className="main-image"
                        alt={image?.fields?.title || ""}
                        width={image?.fields?.file?.details.image.width || 1000}
                        height={
                          image?.fields?.file?.details.image.height || 1000
                        }
                        quality={80}
                        priority={true}
                        src={image?.fields?.file?.url}
                      />
                    </div>
                    {/* Text, Quote, CTA, Logo */}
                    <div className="card-text">
                      <Markdown className="body">{body}</Markdown>
                      {isQuote && (
                        <div className="quote">
                          <div className="title">{quoteName}</div>
                          {quoteTitle && (
                            <div className="subtitle">
                              <Markdown className="body">{quoteTitle}</Markdown>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="cta-logo-wrapper">
                        {bottomLogo && (
                          <ContentfulImage
                            className="main-image"
                            alt={bottomLogo?.fields?.title || ""}
                            width={
                              bottomLogo?.fields?.file?.details.image.width ||
                              1000
                            }
                            height={
                              bottomLogo?.fields?.file?.details.image.height ||
                              1000
                            }
                            quality={80}
                            priority={true}
                            src={bottomLogo?.fields?.file?.url}
                          />
                        )}
                        {contentCta && (
                          <div>
                            <ContentCTA
                              contentCta={{
                                ...contentCta,
                                lang,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Styles.CustomerCard>
              </ColStyle>
            );
          })}
      </Slider>
    </ContainerStyle>
  );
};

export default CustomerCards;
