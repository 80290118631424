import React from "react";

import * as Icons from "react-icons/tfi";

interface IIcon {
  name: any;
}

const Icon = ({ name }: IIcon) => {
  // Old Legacy
  let newname = name;
  if (name === "FaArrowRight") {
    newname = "TfiArrowRight";
  }
  if (name === "FaPlayCircle") {
    newname = "TfiControlPlay";
  }

  const Icon = Icons[newname];
  return <Icon />;
};
export default Icon;
